import { groupBy, sumValues } from '../../services/array-services';
import { addDaysToDate, getDateWithoutTime } from '../../services/date-utilities';
import { NotificationService } from '../../services/notification-service';
import { TimeLogSummary } from '../models/time-log-summary';
import { TimeLogSummaryCompany } from '../models/time-log-summary-company';
import { TimeLogSummaryJob } from '../models/time-log-summary-job';
import { TimeLogSummaryTask } from '../models/time-log-summary-task';
import { TimeLogRepository } from './time-log-repository';

export const TimeLogSummaryRepository = () : {
    getTimeLogSummaryByDateRange: (startDate: Date, endDate: Date, search: string) => Promise<TimeLogSummary[]>
} => {
    const { notify } = NotificationService();
    const { getTimeLogsByDateRange } = TimeLogRepository();
    const getTimeLogSummaryByDateRange = async (startDate: Date, endDate: Date, search: string) : Promise<TimeLogSummary[]> => {
        try {
            const timeLogs = await getTimeLogsByDateRange(startDate, endDate, search);
            const results = new Array<TimeLogSummary>();
            let nextDate = getDateWithoutTime(startDate);
            const minDate = new Date(2000, 0, 1);
            if (nextDate < minDate) {
                nextDate = minDate;
            }
            let finishDate = getDateWithoutTime(endDate);
            const maxDate = new Date(2100, 0, 1);
            if (finishDate > maxDate) {
                finishDate = maxDate;
            }
            while (nextDate < finishDate) {
                // eslint-disable-next-line no-loop-func
                const tlDates = timeLogs.filter((v) => getDateWithoutTime(v.startDate as Date).getTime()
                    === nextDate.getTime());
                const dateModel = {
                    date: nextDate,
                    totalHours: sumValues(tlDates, 'totalHours'),
                    companies: new Array<TimeLogSummaryCompany>(),
                } as TimeLogSummary;
                results.push(dateModel);
                if (tlDates.length > 0) {
                    const companyMap = groupBy(tlDates, 'companyId');
                    // eslint-disable-next-line no-restricted-syntax
                    for (const [companyId, companyTimeLogs] of companyMap.entries()) {
                        const companyModel = {
                            companyId,
                            name: companyTimeLogs[0].company,
                            totalHours: sumValues(companyTimeLogs, 'totalHours'),
                            jobs: new Array<TimeLogSummaryJob>(),
                        } as TimeLogSummaryCompany;
                        dateModel.companies?.push(companyModel);
                        const jobMap = groupBy(companyTimeLogs, 'jobId');
                        // eslint-disable-next-line no-restricted-syntax
                        for (const [jobId, jobTimeLogs] of jobMap.entries()) {
                            const jobModel = {
                                jobId,
                                jobName: jobTimeLogs[0].jobName,
                                totalHours: sumValues(jobTimeLogs, 'totalHours'),
                                tasks: new Array<TimeLogSummaryTask>(),
                            } as TimeLogSummaryJob;
                            companyModel.jobs?.push(jobModel);
                            const taskMap = groupBy(jobTimeLogs, 'taskId');
                            // eslint-disable-next-line no-restricted-syntax
                            for (const [taskId, taskTimeLogs] of taskMap.entries()) {
                                const taskModel = {
                                    taskId,
                                    taskDescription: taskTimeLogs[0].taskDescription,
                                    totalHours: sumValues(taskTimeLogs, 'totalHours'),
                                    timeLogs: taskTimeLogs,
                                } as unknown as TimeLogSummaryTask;
                                jobModel.tasks?.push(taskModel);
                            }
                        }
                    }
                }

                nextDate = addDaysToDate(nextDate, 1);
            }
            return results;
        } catch (error) {
            notify('Error', 'Invalid input', 'danger');
            return [];
        }
    };
    return {
        getTimeLogSummaryByDateRange,
    };
};
