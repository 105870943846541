import React from 'react';
import {
    Button, Stack, Alert, CloseButton, Offcanvas,
} from 'react-bootstrap';
import { NotificationService } from '../services/notification-service';

export const Notifications = () : JSX.Element => {
    const {
        notifications, dismiss, dismissAll, show, onHide,
    } = NotificationService();
    return (
        <>
            <Offcanvas show={show} onHide={onHide} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Notifications</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Button className="w-100" variant="secondary" onClick={() => dismissAll()}>Dismiss All Notifications</Button>
                    <Stack className="mt-2">
                        {
                            notifications.map((n) => (
                                <Alert key={n.id} variant={n.variant} className="w-100">
                                    <strong className="">{n.title}</strong>
                                    <CloseButton
                                      variant="white"
                                      onClick={() => dismiss(n.id as number)}
                                      className="float-end"
                                    />
                                    <div className="clearfix" />
                                    <hr />
                                    {n.message}
                                </Alert>
                            ))
                        }
                    </Stack>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
