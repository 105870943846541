export class TimeLog {
    public tempId : string | undefined;

    public timeLogId : number | undefined;

    public companyId : number | undefined;

    public jobId : number | undefined;

    public notes : string | undefined;

    public rateId : number | undefined;

    public taskId : number | undefined;

    public startDate : Date | undefined;

    public endDate : Date | undefined;

    public isDirty = false;

    public taskDescription : string | undefined;

    public company : string | undefined;

    public jobName : string | undefined;

    public originalStartDate : Date | undefined;

    public originalEndDate : Date | undefined;

    public created : Date | undefined;

    public modified : Date | null | undefined;

    public totalHours : number | undefined;

    public static createTempId() : string {
        return new Date().getTime().toString();
    }
}
