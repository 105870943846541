import { TimeLog } from '../models/TimeLog';
import {
    ApiError, put, post, get, del,
} from '../../services/api-client';
import { fixDates } from '../../services/date-utilities';
import { NotificationService } from '../../services/notification-service';
import { UserImpersonationService } from '../../services/user-impersonation-service';

export const TimeLogRepository = () : {
    saveTimeLog: (timeLog: TimeLog) => Promise<TimeLog | null>,
    getTimeLogsByDateRange: (startDate: Date, endDate: Date, search?: string) => Promise<TimeLog[]>,
    getTimeLogById: (timeLogId: number) => Promise<TimeLog | null>,
    deleteTimeLog: (timeLogId: number) => Promise<boolean>,
} => {
    const { notify } = NotificationService();
    const { impersonationUserId } = UserImpersonationService();
    function fixData(timeLogs: TimeLog[]) : void {
        fixDates(timeLogs, ['startDate', 'endDate', 'created', 'modified']);
        for (let i = 0; i < timeLogs.length; i += 1) {
            const timeLog = timeLogs[i];
            timeLog.tempId = `TL${timeLog.timeLogId}`;
            timeLog.originalStartDate = new Date(timeLog.startDate as Date);
            timeLog.originalEndDate = new Date(timeLog.endDate as Date);
        }
    }
    const saveTimeLog = async (timeLog: TimeLog) : Promise<TimeLog | null> => {
        try {
            const path = impersonationUserId > 0 ? `timelogs/${impersonationUserId}` : 'timelogs';
            const result = await put<TimeLog>(path, timeLog);
            fixData([result]);
            return result;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to save time log entry: ${apiError.message}`, 'danger');
            return null;
        }
    };
    const getTimeLogsByDateRange = async (startDate: Date, endDate: Date, search?: string) : Promise<TimeLog[]> => {
        try {
            const path = impersonationUserId > 0
                ? `timelogs/userbydaterange/${impersonationUserId}` : 'timelogs/currentuserbydaterange';
            const results = await post<TimeLog[]>(path, { startDate, endDate, search });
            fixData(results);
            return results;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve time log entries: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const getTimeLogById = async (timeLogId: number) : Promise<TimeLog | null> => {
        try {
            const result = await get<TimeLog>(`timelogs/${timeLogId}`);
            fixData([result]);
            return result;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve time log entry: ${apiError.message}`, 'danger');
            return null;
        }
    };
    const deleteTimeLog = async (timeLogId: number) : Promise<boolean> => {
        try {
            await del<string>(`timelogs/${timeLogId}`);
            return true;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to delete time log entry: ${apiError.message}`, 'danger');
            return false;
        }
    };
    return {
        saveTimeLog, getTimeLogsByDateRange, getTimeLogById, deleteTimeLog,
    };
};
