import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Loading } from '../../components/loading';
import { RestrictedContentProvider } from '../../components/restricted-content';
import { OnTimeSyncItemsService } from '../services/ontime-item-sync-service';

export const OnTimeItemsSync = () : JSX.Element => {
    const { syncOnTimeItems } = OnTimeSyncItemsService();
    const [syncingOnTimeItems, setSyncingOnTimeItems] = useState<boolean>(false);

    const startSyncingOnTimeItems = async () : Promise<void> => {
        setSyncingOnTimeItems(true);
        await syncOnTimeItems();
        setSyncingOnTimeItems(false);
    };

    if (syncingOnTimeItems) {
        return (
            <Loading loadingMessage="Syncing OnTime items, please wait..." />
        );
    }

    return (
        <RestrictedContentProvider projectManagerOnly>
            <>
                <Row>
                    <Col
                      lg={{ span: 4, offset: 4 }}
                      className="d-flex align-items-center"
                    >
                        <Button
                          variant="secondary"
                          className="w-100 mb-1"
                          onClick={() => startSyncingOnTimeItems()}
                        >
                            Sync On Time Items
                        </Button>
                    </Col>
                </Row>
            </>
        </RestrictedContentProvider>
    );
};
