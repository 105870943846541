export class User {
    public userId : number | undefined;

    public username : string | undefined;

    public email : string | undefined;

    public onTimeUserId : number | null | undefined;

    public onTimePassword : string | undefined;

    public onTimeUsername : string | undefined;

    public created : Date | undefined;

    public modified : Date | null | undefined;

    public active : boolean | undefined;

    public applicationId : string | null | undefined;
}
