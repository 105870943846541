import { DateTime } from 'luxon';

export const formatToShortIsoString = (date: Date | null | undefined) : string => {
    if (date) {
        const dt = DateTime.fromISO(date.toISOString());
        return dt.toFormat('yyyy-MM-dd');
    }
    return '';
};

export const formatDateTimeString = (date: Date | null | undefined) : string => {
    if (date) {
        const dt = DateTime.fromISO(date.toISOString());
        return dt.toFormat('yyyy-MM-dd HH:mm');
    }
    return '';
};

export const formatLongDateString = (date: Date | null | undefined) : string => {
    if (date) {
        const dt = DateTime.fromISO(date.toISOString());
        return dt.toFormat('cccc dd LLLL yyyy');
    }
    return '';
};

export const formatTimeString = (date: Date | null | undefined) : string => {
    if (date) {
        const dt = DateTime.fromISO(date.toISOString());
        return dt.toFormat('HH:mm');
    }
    return '';
};

export const parseIsoString = (isoString: string) : Date | null => {
    const dt = DateTime.fromISO(isoString);
    if (dt.isValid) {
        return dt.toJSDate();
    }
    return null;
};

export const fixDates = <T, K extends keyof T>(array: T[], properties: K[]) : void => {
    for (let i = 0; i < array.length; i += 1) {
        const entity = array[i];
        for (let j = 0; j < properties.length; j += 1) {
            const property = properties[j];
            entity[property] = parseIsoString(entity[property] as unknown as string) as any;
        }
    }
};

export const getDateWithoutTime = (dateTime : Date) : Date => {
    const date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
};

export const addDaysToDate = (dateTime : Date, numberOfDays : number) : Date => {
    const date = new Date(dateTime.getTime());
    date.setDate(date.getDate() + numberOfDays);
    return date;
};
