import React, { useCallback, useEffect, useState } from 'react';
import {
    Button, Col, Form, Row,
} from 'react-bootstrap';
import DataTable, { ExpanderComponentProps, Media } from 'react-data-table-component';
import { Loading } from '../../components/loading';
import { RestrictedContentProvider } from '../../components/restricted-content';
import { formatDateTimeString } from '../../services/date-utilities';
import { User } from '../models/user';
import { UserRepository } from '../repositories/user-repository';
import { EditUser } from './edit-user';

export const UserMaintenance = () : JSX.Element => {
    const [users, setUsers] = useState<User[]>(new Array<User>());
    const [loadingUsers, setLoadingUsers] = useState<boolean>(true);
    const [userId, setUserId] = useState<number>(0);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const { getUsers } = UserRepository();
    const [activeOnly, setActiveOnly] = useState<boolean>(true);

    const handleCloseShowEditDialog = (refresh: boolean) : void => {
        setUserId(0);
        setShowEditDialog(false);
        if (refresh) {
            loadUsers();
        }
    };

    const loadUsers = useCallback(async () : Promise<void> => {
        setLoadingUsers(true);
        const data = await getUsers(!activeOnly);
        setUsers(data);
        setLoadingUsers(false);
    }, [activeOnly]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const columns = [
        {
            name: 'Id',
            selector: (row: User) => row.userId as number,
            sortable: true,
            hide: Media.SM,
            width: '80px;',
        },
        {
            name: 'Username',
            selector: (row: User) => row.username as string,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Email',
            selector: (row: User) => row.email as string,
            grow: 2,
            sortable: true,
            hide: Media.MD,
        },
        {
            name: 'Active',
            selector: (row: User) => (row.active ? 'true' : 'false'),
            sortable: true,
            center: true,
            hide: Media.MD,
            width: '80px;',
        },
        {
            name: 'Created',
            selector: (row: User) => formatDateTimeString(row.created),
            sortable: true,
            right: true,
            hide: Media.LG,
            width: '140px;',
        },
        {
            name: 'Modified',
            selector: (row: User) => formatDateTimeString(row.modified),
            sortable: true,
            right: true,
            hide: Media.LG,
            width: '140px;',
        },
        {
            cell: (row: User) => (
                <Button
                  onClick={onEditClick}
                  id={(row.userId as number).toString()}
                >
                    Edit
                </Button>
            ),
            allowOverflow: true,
            button: true,
        },
    ];

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        setUserId(parseFloat(event.currentTarget.id));
        setShowEditDialog(true);
    };

    const onShowActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setActiveOnly(event.target.checked);
    };

    const onAddNewClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        setUserId(0);
        setShowEditDialog(true);
    };

    const expandedComponent = ({ data } : ExpanderComponentProps<User>)
    : JSX.Element => (
        <dl className="p-2">
            <dt>Id</dt>
            <dd>{data.userId}</dd>
            <dt>Username</dt>
            <dd>{data.username}</dd>
            <dt>Email</dt>
            <dd>{data.email}</dd>
            <dt>Application Id</dt>
            <dd>{data.applicationId}</dd>
            <dt>OnTime Username</dt>
            <dd>{data.onTimeUsername}</dd>
            <dt>OnTime UserId</dt>
            <dd>{data.onTimeUserId}</dd>
            <dt>OnTime Password</dt>
            <dd>{data.onTimePassword}</dd>
            <dt>Is Active</dt>
            <dd>{data.active ? 'true' : 'false'}</dd>
            <dt>Created</dt>
            <dd>{formatDateTimeString(data.created)}</dd>
            <dt>Modified</dt>
            <dd>{formatDateTimeString(data.modified)}</dd>
        </dl>
    );

    return (
        <RestrictedContentProvider adminOnly>
            <EditUser
              userId={userId}
              show={showEditDialog}
              onHide={handleCloseShowEditDialog}
            />
            <h3>Users</h3>
            <Form.Group as={Row} className="mb-2 gx-2 d-flex align-items-center">
                <Col>
                    <Form.Check className="mb-2 mb-lg-0">
                        <Form.Check.Input
                          id="showActiveUsersOnly"
                          type="checkbox"
                          checked={activeOnly}
                          onChange={onShowActiveOnlyChange}
                        />
                        <Form.Check.Label
                          className="ms-2"
                          htmlFor="showActiveUsersOnly"
                        >
                            Show Active Users Only
                        </Form.Check.Label>
                    </Form.Check>
                </Col>
                <Col md="auto">
                    <Button
                      variant="primary"
                      onClick={onAddNewClick}
                      className="w-100"
                    >
                        Add New User
                    </Button>
                </Col>
            </Form.Group>
            <DataTable<User>
              columns={columns}
              data={users}
              expandableRows
              expandableRowsComponent={expandedComponent}
              pagination
              progressPending={loadingUsers}
              progressComponent={<Loading />}
              striped
            />
        </RestrictedContentProvider>
    );
};
