import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DataTable, { Media } from 'react-data-table-component';
import { Loading } from '../../components/loading';
import { RestrictedContentProvider } from '../../components/restricted-content';
import { Company } from '../models/company';
import { CompanyRepository } from '../repositories/company-repository';

export const CompanyMaintenance = () : JSX.Element => {
    const [companies, setCompanies] = useState<Company[]>(new Array<Company>());
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const { getCompanies, syncCompanies } = CompanyRepository();
    const [syncingCompanies, setSyncingCompanies] = useState<boolean>(false);

    const startSyncingCompanies = async () : Promise<void> => {
        setSyncingCompanies(true);
        const data = await syncCompanies();
        setCompanies(data);
        setSyncingCompanies(false);
    };

    const loadCompanies = useCallback(async () : Promise<void> => {
        setLoadingCompanies(true);
        const data = await getCompanies();
        setCompanies(data);
        setLoadingCompanies(false);
    }, []);

    useEffect(() => {
        loadCompanies();
    }, []);

    if (syncingCompanies) {
        return (
            <Loading loadingMessage="Syncing companies, please wait..." />
        );
    }

    const columns = [
        {
            name: 'Id',
            selector: (row: Company) => row.companyId as number,
            sortable: true,
            hide: Media.MD,
            width: '150px',
        },
        {
            name: 'Name',
            selector: (row: Company) => row.name as string,
            sortable: true,
        },
        {
            name: 'CRM Id',
            selector: (row: Company) => row.crmCompanyId as string,
            sortable: true,
            hide: Media.SM,
            width: '150px',
        },
        {
            name: 'Active',
            selector: (row: Company) => (row.isActive ? 'active' : 'inactive'),
            sortable: true,
            center: true,
            hide: Media.MD,
            width: '150px',
        },
    ];
    return (
        <RestrictedContentProvider projectManagerOnly>
            <>
                <h3>Companies</h3>
                <Row>
                    <Col
                      lg={{ span: 4, offset: 4 }}
                      className="d-flex align-items-center"
                    >
                        <Button
                          disabled={syncingCompanies}
                          variant="secondary"
                          className="w-100 mb-1"
                          onClick={() => startSyncingCompanies()}
                        >
                            Sync Companies
                        </Button>
                    </Col>
                </Row>
                <DataTable<Company>
                  columns={columns}
                  data={companies}
                  pagination
                  progressPending={loadingCompanies}
                  progressComponent={<Loading />}
                  striped
                  dense
                />
            </>
        </RestrictedContentProvider>
    );
};
