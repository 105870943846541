import React, { useEffect, useState } from 'react';
import { CurrentUserService } from '../services/current-user-service';

export const RestrictedContentProvider = (props: {
        adminOnly?: boolean,
        projectManagerOnly?: boolean,
        children?: React.ReactNode }) : JSX.Element => {
    const { adminOnly, projectManagerOnly, children } = props;
    const { currentUser } = CurrentUserService();
    const [hasPermission, setHasPermission] = useState<boolean | null>(null);

    useEffect(() => {
        if (currentUser) {
            let p = false;
            if (adminOnly && currentUser.isAdmin) {
                p = true;
            }
            if (projectManagerOnly && (currentUser.isAdmin || currentUser.isProjectManager)) {
                p = true;
            }
            setHasPermission(p);
        }
    }, [currentUser]);

    if (hasPermission === null) {
        return <></>;
    }

    if (!hasPermission) {
        return (
            <div className="text-center mt-5">
                <p>Sorry you do not have access to this resource!</p>
                <p>Please contact your systems administrator.</p>
            </div>
        );
    }

    return (
        <>
            { children }
        </>
    );
};

RestrictedContentProvider.defaultProps = {
    adminOnly: false,
    projectManagerOnly: false,
    children: <></>,
};
