export class Routes {
    public static TimeSheet = '/timesheet';

    public static Users = '/users';

    public static Companies = '/companies';

    public static Jobs = '/jobs';

    public static Tasks = '/tasks';

    public static Dashboard = '/reports/dashboard';

    public static SyncOnTimeItems = '/syncontimeitems';
}
