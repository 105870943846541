import { createContext } from 'react';
import { CurrentUser } from '../users/models/current-user';

export interface ICurrentUserProviderContext {
    currentUser: CurrentUser | null
}

export const CurrentUserContext = createContext({
    currentUser: null as CurrentUser | null,
} as ICurrentUserProviderContext);
