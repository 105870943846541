import React from 'react';
import { Stack } from 'react-bootstrap';
import { SignInButton } from './sign-in-button';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInContent = () : JSX.Element => (
    <Stack gap={1} className="mt-5 col-md-5 mx-auto">
        <p className="text-center">Welcome to Logit 2.0</p>
        <p className="text-center">Please sign in</p>
        <SignInButton />
    </Stack>
);
