import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Button,
    Col,
    Form,
    InputGroup,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import DataTable from 'react-data-table-component';
import { sumValues } from '../services/array-services';
import {
    addDaysToDate,
    formatLongDateString,
    formatTimeString,
    formatToShortIsoString,
    getDateWithoutTime,
    parseIsoString,
} from '../services/date-utilities';
import { UserImpersonationService } from '../services/user-impersonation-service';
import { TimeLogSummary } from '../time-log/models/time-log-summary';
import { TimeLogSummaryCompany } from '../time-log/models/time-log-summary-company';
import { TimeLogSummaryJob } from '../time-log/models/time-log-summary-job';
import { TimeLogSummaryTask } from '../time-log/models/time-log-summary-task';
import { TimeLog } from '../time-log/models/TimeLog';
import { TimeLogSummaryRepository } from '../time-log/repositories/time-log-summary-repository';
import { InputGroupSpinner } from './input-group-spinner';
import { Loading } from './loading';

interface ISearchParameters {
    startDate : string;
    endDate: string;
}

export const Dashboard = () : JSX.Element => {
    const initialStartDate = new Date(new Date().getFullYear(), new Date().getMonth());
    const intitialEndDate = new Date();
    const { impersonationUserId } = UserImpersonationService();
    const { getTimeLogSummaryByDateRange } = TimeLogSummaryRepository();
    const [searchParameters, setSearchParameters] = useState<ISearchParameters>({
        startDate: formatToShortIsoString(initialStartDate),
        endDate: formatToShortIsoString(intitialEndDate),
    });
    const [loadingTimeLogSummary, setLoadingTimeLogSummary] = useState<boolean>(false);
    const [timeLogSummary, setTimeLogSummary] = useState<TimeLogSummary[]>(new Array<TimeLogSummary>());
    const [totalHours, setTotalHours] = useState<number>(0);
    const searchRef = useRef<HTMLInputElement>(null);

    const loadTimeSummary = async () : Promise<void> => {
        setLoadingTimeLogSummary(true);
        let dStart = parseIsoString(searchParameters.startDate);
        let dFinish = parseIsoString(searchParameters.endDate);
        let data = new Array<TimeLogSummary>();
        if (dStart && dFinish) {
            dStart = getDateWithoutTime(dStart);
            dFinish = addDaysToDate(getDateWithoutTime(dFinish), 1);
            const search = searchRef.current?.value || '';
            data = await getTimeLogSummaryByDateRange(dStart, dFinish, search);
        }
        setTotalHours(sumValues(data, 'totalHours'));
        setTimeLogSummary(data);
        setLoadingTimeLogSummary(false);
    };

    const loadTimeSummaryCallback = useCallback(async () : Promise<void> => {
        loadTimeSummary();
    }, [impersonationUserId, searchParameters]);

    useEffect(() => {
        loadTimeSummaryCallback();
    }, [loadTimeSummaryCallback]);

    const onStartDateSelectionChanged = (arg : any) : void => {
        setSearchParameters({ ...searchParameters, startDate: arg.target.value });
    };

    const onEndDateSelectionChanged = (arg : any) : void => {
        setSearchParameters({ ...searchParameters, endDate: arg.target.value });
    };

    const handleSearchSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
        event.stopPropagation();
        loadTimeSummary();
    };

    const onClearSearchClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        if (searchRef.current) {
            searchRef.current.value = '';
            searchRef.current.focus();
        }
        loadTimeSummary();
    };

    const dateColumns = [
        {
            name: 'Date',
            selector: (row: TimeLogSummary) => row.date?.getTime() as number,
            format: (row: TimeLogSummary) => formatLongDateString(row.date),
            sortable: true,
        },
        {
            name: 'Hours',
            selector: (row: TimeLogSummary) => row.totalHours as number,
            format: (row: TimeLogSummary) => row.totalHours?.toFixed(2) || '0.00',
            sortable: true,
            width: '100px',
            right: true,
        },
    ];

    const companyColumns = [
        {
            name: 'Company',
            selector: (row: TimeLogSummaryCompany) => row.name as string,
            sortable: true,
        },
        {
            name: 'Hours',
            selector: (row: TimeLogSummaryCompany) => row.totalHours as number,
            format: (row: TimeLogSummaryCompany) => row.totalHours?.toFixed(2) || '0.00',
            sortable: true,
            width: '100px',
            right: true,
        },
    ];

    const jobColumns = [
        {
            name: 'Job',
            selector: (row: TimeLogSummaryJob) => row.jobName as string,
            sortable: true,
        },
        {
            name: 'Hours',
            selector: (row: TimeLogSummaryJob) => row.totalHours as number,
            format: (row: TimeLogSummaryJob) => row.totalHours?.toFixed(2) || '0.00',
            sortable: true,
            width: '100px',
            right: true,
        },
    ];

    const taskColumns = [
        {
            name: 'Task',
            selector: (row: TimeLogSummaryTask) => row.taskDescription as string,
            sortable: true,
        },
        {
            name: 'Hours',
            selector: (row: TimeLogSummaryTask) => row.totalHours as number,
            format: (row: TimeLogSummaryTask) => row.totalHours?.toFixed(2) || '0.00',
            sortable: true,
            width: '100px',
            right: true,
        },
    ];

    const timeLogColumns = [
        {
            name: 'Start',
            selector: (row: TimeLog) => row.startDate?.getTime() as number,
            format: (row: TimeLog) => formatTimeString(row.startDate),
            width: '100px',
            sortable: true,
        },
        {
            name: 'Finish',
            selector: (row: TimeLog) => row.endDate?.getTime() as number,
            format: (row: TimeLog) => formatTimeString(row.endDate),
            width: '100px',
            sortable: true,
        },
        {
            name: 'Notes',
            selector: (row: TimeLog) => row.notes as string,
            sortable: true,
        },
        {
            name: 'Hours',
            selector: (row: TimeLog) => row.totalHours as number,
            format: (row: TimeLog) => row.totalHours?.toFixed(2) || '0.00',
            sortable: true,
            width: '100px',
            right: true,
        },
    ];

    const expandedTaskComponent = ({ data } : any) : any => (
        <div className="ps-5 pe-3 border-start border-end">
            <DataTable<TimeLog>
              columns={timeLogColumns}
              data={data.timeLogs}
              striped
              dense
            />
        </div>
    );

    const expandedJobComponent = ({ data } : any) : any => (
        <div className="ps-3 pe-3 border-start border-end">
            <DataTable<TimeLogSummaryTask>
              columns={taskColumns}
              data={data.tasks}
              expandableRows
              expandableRowsComponent={expandedTaskComponent}
              expandableRowExpanded={() => true}
              striped
              dense
            />
        </div>
    );

    const expandedCompanyComponent = ({ data } : any) : any => (
        <div className="ps-3 pe-3 border-start border-end">
            <DataTable<TimeLogSummaryJob>
              columns={jobColumns}
              data={data.jobs}
              expandableRows
              expandableRowsComponent={expandedJobComponent}
              expandableRowExpanded={() => true}
              striped
              dense
            />
        </div>
    );

    const expandedDateComponent = ({ data } : any) : any => (
        <div className="ps-3">
            <DataTable<TimeLogSummaryCompany>
              columns={companyColumns}
              data={data.companies}
              expandableRows
              expandableRowsComponent={expandedCompanyComponent}
              striped
              dense
            />
        </div>
    );

    const companyRowStyles = [
        {
            when: (row: TimeLogSummary) => row.totalHours as number >= 8,
            style: {
                backgroundColor: 'rgba(63, 195, 128, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: (row: TimeLogSummary) => row.totalHours as number > 0 && row.totalHours as number < 8,
            style: {
                backgroundColor: 'rgba(248, 148, 6, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
        {
            when: (row: TimeLogSummary) => ((row.date?.getDay()
            || 0) > 0 && (row.date?.getDay() || 0) < 6) && row.totalHours as number === 0,
            style: {
                backgroundColor: 'rgba(242, 38, 19, 0.9)',
                color: 'white',
                '&:hover': {
                    cursor: 'pointer',
                },
            },
        },
    ];

    return (
        <>
            <h3>Dashboard</h3>
            <Row className="mb-2 gx-2 d-flex align-items-center">
                <Col lg="4" xl="3">
                    <InputGroup className="mb-2 mb-xl-0">
                        <InputGroup.Text>Start Date</InputGroup.Text>
                        <Form.Control type="date" value={searchParameters.startDate} onChange={onStartDateSelectionChanged} />
                    </InputGroup>
                </Col>
                <Col lg="4" xl="3">
                    <InputGroup className="mb-2 mb-xl-0">
                        <InputGroup.Text>End Date</InputGroup.Text>
                        <Form.Control type="date" value={searchParameters.endDate} onChange={onEndDateSelectionChanged} />
                    </InputGroup>
                </Col>
                <Col lg="4" xl="3">
                    <Form noValidate onSubmit={handleSearchSubmit}>
                        <InputGroup className="mb-2 mb-xl-0">
                            <Form.Control ref={searchRef} />
                            {loadingTimeLogSummary
                                ? <InputGroupSpinner />
                                : (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={<Tooltip>Search by Company, Job, Task, Note</Tooltip>}
                                    >
                                        <Button variant="secondary" type="submit"><Search /></Button>
                                    </OverlayTrigger>
                                )}
                            <Button
                              variant="secondary"
                              onClick={onClearSearchClick}
                              disabled={loadingTimeLogSummary}
                            >
                                Clear
                            </Button>
                        </InputGroup>
                    </Form>
                </Col>
                <Col className="text-center text-xl-end text-muted fw-bold">
                    Total Hours:
                    {' '}
                    {totalHours.toFixed(2)}
                </Col>
            </Row>
            <DataTable<TimeLogSummary>
              columns={dateColumns}
              data={timeLogSummary}
              expandableRows
              expandableRowsComponent={expandedDateComponent}
              pagination
              progressPending={loadingTimeLogSummary}
              progressComponent={<Loading />}
              conditionalRowStyles={companyRowStyles}
              paginationRowsPerPageOptions={[7, 14, 28, 31, 60, 90]}
              paginationPerPage={31}
              dense
            />
        </>
    );
};
