import React, { useCallback, useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Col,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Loading } from '../../components/loading';
import { BasicUser } from '../../users/models/basic-user';
import { UserTask } from '../models/user-task';
import { UserTaskPermissions } from '../models/user-task-permissions';
import { UserTaskRepository } from '../repositories/user-task-repository';

interface TaskUserProps {
    taskId : number,
    users : BasicUser[],
    onHide: () => void
}

export const TaskUser = ({
    taskId,
    users,
    onHide,
}: TaskUserProps): JSX.Element => {
    const [filteredUsers, setFilteredUsers] = useState<BasicUser[]>(new Array<BasicUser>());
    const [userId, setUserId] = useState<number>(0);
    const [savingUserTaskPermissions, setSavingUserTaskPermissions] = useState<boolean>(false);
    const [loadingUserTaskPermisssions, setLoadingUserTaskPermissions] = useState<boolean>(false);
    const { saveUserTaskPermissions, getUserTaskPermissions } = UserTaskRepository();
    const [userTaskPermissions, setUserTaskPermissions] = useState<UserTaskPermissions | null>(null);

    const loadFilteredUsers = useCallback(async () : Promise<void> => {
        const userTasks = userTaskPermissions?.users || new Array<UserTask>();
        const data = users.filter((u) => !userTasks.find((ut) => ut.userId === u.userId));
        setUserId(data.length > 0 ? data[0].userId as number : 0);
        setFilteredUsers(data);
    }, [userTaskPermissions]);

    const loadUserTaskPermissions = useCallback(async () : Promise<void> => {
        setLoadingUserTaskPermissions(true);
        const data = taskId > 0 ? await getUserTaskPermissions(taskId) : null;
        setUserTaskPermissions(data);
        setLoadingUserTaskPermissions(false);
    }, [taskId]);

    useEffect(() => {
        loadUserTaskPermissions();
    }, [loadUserTaskPermissions]);

    useEffect(() => {
        loadFilteredUsers();
    }, [loadFilteredUsers]);

    const startSavingUserTaskPermissions = async () : Promise<void> => {
        setSavingUserTaskPermissions(true);
        const result = await saveUserTaskPermissions(userTaskPermissions as UserTaskPermissions);
        if (result != null) {
            setUserTaskPermissions(result);
            onHide();
        }
        setSavingUserTaskPermissions(false);
    };
    const onRemoveUserClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        const id = parseFloat(event.currentTarget.id);
        const userTasks = userTaskPermissions?.users?.filter((ut) => ut.userId !== id) || new Array<UserTask>();
        setUserTaskPermissions({ ...userTaskPermissions as UserTaskPermissions, users: [...userTasks] });
    };
    const onAddUserClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        const user = users.find((u) => u.userId === userId);
        if (user) {
            const userTask = {
                userId,
                username: user.username,
            } as UserTask;
            const userTasks = userTaskPermissions?.users || new Array<UserTask>();
            userTasks.push(userTask);
            setUserTaskPermissions({ ...userTaskPermissions as UserTaskPermissions, users: [...userTasks] });
        }
    };
    const onUserChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setUserId(parseFloat(event.target.value));
    };
    const onPermissionsEnabledChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setUserTaskPermissions({ ...userTaskPermissions as UserTaskPermissions, enabled: event.target.checked });
    };
    const onDisallowSelectedUsers = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setUserTaskPermissions({ ...userTaskPermissions as UserTaskPermissions, disallowSelectedUsers: event.target.checked });
    };
    const columns = [
        {
            name: 'Username',
            selector: (row: UserTask) => row.username as string,
            sortable: true,
            allowOverflow: true,
            wrap: true,
        },
        {
            cell: (row: UserTask) => (
                <Button
                  onClick={onRemoveUserClick}
                  id={(row.userId as number).toString()}
                >
                    Remove
                </Button>
            ),
            button: true,
            width: '120px',
        },
    ];

    const disallowSelectedUserText = (
        <>
            The selected users will
            <b> NOT </b>
            be able to create, edit or delete timelog entries for this task
        </>
    );
    const onlyAllowSelectedUserText = (
        <>
            Only the selected users will be able to create, edit or delete timelog entries for this task
        </>
    );

    if (savingUserTaskPermissions) {
        return <Loading loadingMessage="Saving permisssions, please wait..." />;
    }

    return (
        <>
            <h5>
                Task Permissions:
                &nbsp;
                {taskId}
            </h5>
            <p className="small-text">Administrators and Project Managers are not affected by these permissions.</p>
            <Col>
                <Form.Check className="mb-2">
                    <Form.Check.Input
                      id="permissionsEnabled"
                      type="checkbox"
                      checked={userTaskPermissions?.enabled || false}
                      onChange={onPermissionsEnabledChange}
                    />
                    <Form.Check.Label
                      className="ms-2"
                      htmlFor="permissionsEnabled"
                    >
                        Enable Permissions
                    </Form.Check.Label>
                </Form.Check>
            </Col>
            <div className={userTaskPermissions?.enabled || false ? '' : 'd-none'}>
                <Col>
                    <Form.Check className="mb-2">
                        <Form.Check.Input
                          id="disallowSelectedUsers"
                          type="checkbox"
                          checked={userTaskPermissions?.disallowSelectedUsers || false}
                          onChange={onDisallowSelectedUsers}
                        />
                        <Form.Check.Label
                          className="ms-2"
                          htmlFor="disallowSelectedUsers"
                        >
                            Disallow Selected Users
                        </Form.Check.Label>
                    </Form.Check>
                </Col>
                <p className="small-text">
                    {
                        userTaskPermissions?.disallowSelectedUsers || false
                            ? disallowSelectedUserText
                            : onlyAllowSelectedUserText
                    }
                </p>
                <InputGroup className="mb-2 mb-md-0">
                    <Form.Select onChange={onUserChange} value={userId} className="w-auto">
                        {
                            filteredUsers.map((user) => (
                                <option
                                  key={user.userId}
                                  value={user.userId}
                                >
                                    {user.username}
                                </option>
                            ))
                        }
                    </Form.Select>
                    <Button
                      variant="primary"
                      onClick={onAddUserClick}
                      disabled={userId === 0 || loadingUserTaskPermisssions}
                    >
                        Add User
                    </Button>
                </InputGroup>
                <DataTable<UserTask>
                  columns={columns}
                  data={userTaskPermissions?.users || []}
                  pagination
                  progressPending={loadingUserTaskPermisssions}
                  progressComponent={<Loading />}
                />
            </div>
            <Form.Group as={Row} className="mb-2 gx-2 justify-content-end">
                <Col lg="auto">
                    <Button
                      className="w-100 mb-2 mb-lg-0"
                      variant="secondary"
                      onClick={() => onHide()}
                    >
                        Cancel
                    </Button>
                </Col>
                <Col lg="auto">
                    <Button
                      className="w-100"
                      variant="primary"
                      onClick={() => startSavingUserTaskPermissions()}
                      disabled={loadingUserTaskPermisssions}
                    >
                        Save Permisions
                    </Button>
                </Col>
            </Form.Group>
        </>
    );
};
