import { useIsAuthenticated } from '@azure/msal-react';
import React, { useCallback, useEffect, useState } from 'react';
import { CurrentUserContext, ICurrentUserProviderContext } from '../services/current-user-context';
import { UserRepository } from '../users/repositories/user-repository';

export const CurrentUserProvider = ({ children } : any) : JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    const { getCurrentUser } = UserRepository();
    const [context, setContext] = useState<ICurrentUserProviderContext>(
        {
            currentUser: null,
        },
    );

    const loadCurrentUser = useCallback(async () : Promise<void> => {
        if (!isAuthenticated) return;
        const user = await getCurrentUser();
        if (user) {
            setContext({
                currentUser: user,
            });
        }
    }, [getCurrentUser, isAuthenticated]);

    useEffect(() => {
        loadCurrentUser();
    }, [isAuthenticated]);

    return (
        <CurrentUserContext.Provider value={context}>
            {children}
        </CurrentUserContext.Provider>
    );
};
