import React, { useCallback, useEffect, useState } from 'react';
import {
    Button, Col, Form, FormControl, InputGroup, Modal, OverlayTrigger, Row, Tab, Tabs, Tooltip,
} from 'react-bootstrap';
import { ArrowRepeat } from 'react-bootstrap-icons';
import { Company } from '../../companies/models/company';
import { CompanyRepository } from '../../companies/repositories/company-repository';
import { InputGroupSpinner } from '../../components/input-group-spinner';
import { InvalidInputModal } from '../../components/invalid-input-modal';
import { Rate } from '../../rates/models/rate';
import { RateRepository } from '../../rates/repositories/rate-repository';
import { OnTimeReleaseRepository } from '../../ontime/repositories/ontime-release-repository';
import { OnTimeRelease } from '../../ontime/models/ontime-release';
import { OnTimeProject } from '../../ontime/models/ontime-project';
import { OnTimeProjectRepository } from '../../ontime/repositories/ontime-project-repository';
import { JobRepository } from '../repositories/job-repository';
import { Job } from '../models/job';
import { Loading } from '../../components/loading';
import { EditJobTasks } from './edit-job-tasks';

export const EditJob = (props: {jobId : number,
    show: boolean,
    onHide: (refresh: boolean) => void}) : JSX.Element => {
    const { jobId, show, onHide } = props;
    const { getCompanies, syncCompanies } = CompanyRepository();
    const [companies, setCompanies] = useState<Company[]>(new Array<Company>());
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true);
    const [companyId, setCompanyId] = useState<number>(0);
    const { getRates } = RateRepository();
    const [rates, setRates] = useState<Rate[]>(new Array<Rate>());
    const [loadingRates, setLoadingRates] = useState<boolean>(true);
    const [rateId, setRateId] = useState<number>(0);

    const { getOnTimeReleases } = OnTimeReleaseRepository();
    const [loadingOnTimeReleases, setLoadingOnTimeReleases] = useState<boolean>(true);
    const [onTimeProductReleases, setOnTimeProductReleases] = useState<OnTimeRelease[]>();
    const [onTimeReleaseProductId, setOnTimeReleaseProductId] = useState<number>(0);
    const [onTimeVersionReleases, setOnTimeVersionReleases] = useState<OnTimeRelease[]>(
        new Array<OnTimeRelease>(),
    );
    const [onTimeReleaseVersionId, setOnTimeReleaseVersionId] = useState<number>(0);
    const [onTimeSprintReleases, setOnTimeSprintReleases] = useState<OnTimeRelease[]>(
        new Array<OnTimeRelease>(),
    );
    const [onTimeReleaseSprintId, setOnTimeReleaseSprintId] = useState<number>(0);

    const { getOnTimeProjects } = OnTimeProjectRepository();
    const [loadingOnTimeProjects, setLoadingOnTimeProjects] = useState<boolean>(true);
    const [onTimeProjects, setOnTimeProjects] = useState<OnTimeProject[]>(
        new Array<OnTimeProject>(),
    );
    const [onTimeSupportProjectId, setOnTimeSupportProjectId] = useState<number>(0);

    const [showInvalidInputModal, setShowInvalidInputModal] = useState<boolean>(false);
    const [invalidInputErrors, setInvalidInputErrors] = useState<string[]>(new Array<string>());
    const [invalidInputTitle, setInvalidInputTitle] = useState<string>('');
    const [jobName, setJobName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [isOnTimeSprint, setIsOnTimeSprint] = useState<boolean>(false);
    const [isOnTimeSupportProject, setIsOnTimeSupportProject] = useState<boolean>(false);
    const [savingJob, setSavingJob] = useState<boolean>(false);
    const { getJobById, saveJob } = JobRepository();
    const [activeTab, setActiveTab] = useState<string | null>('details');
    const [syncingCompanies, setSyncingCompanies] = useState<boolean>(false);

    const errorHandler = (title: string, message: string) : void => {
        setInvalidInputErrors([message]);
        setInvalidInputTitle(title);
        setShowInvalidInputModal(true);
    };

    const beginSyncingCompanies = async () : Promise<void> => {
        setSyncingCompanies(true);
        const data = await syncCompanies();
        data.unshift({
            companyId: 0,
            name: 'Please Select...',
        } as Company);
        setCompanies(data);
        setSyncingCompanies(false);
    };

    const save = async () : Promise<void> => {
        const errors = [];
        if (companyId < 1) {
            errors.push('Please select a company.');
        }
        if (jobName == null || jobName.trim().length < 1) {
            errors.push('Please enter a job name.');
        }
        if (rateId < 1) {
            errors.push('Please select a rate.');
        }
        if (description == null || description.trim().length < 1) {
            errors.push('Please enter a job description.');
        }
        if (isOnTimeSprint) {
            if (onTimeReleaseSprintId < 1) {
                errors.push('Please select a sprint.');
            }
        } else if (isOnTimeSupportProject) {
            if (onTimeSupportProjectId < 1) {
                errors.push('Please select a project.');
            }
        } else {
            errors.push('Please check Is OnTime Project Job or Is OnTime Support Job.');
        }
        if (errors.length > 0) {
            setInvalidInputErrors(errors);
            setShowInvalidInputModal(true);
            setInvalidInputTitle('Invalid Input');
            return;
        }

        setSavingJob(true);
        const job = new Job();
        job.jobId = jobId;
        job.companyId = companyId;
        job.jobName = jobName;
        job.rateId = rateId;
        job.description = description;
        job.isOnTimeProject = isOnTimeSprint;
        job.isOnTimeSupportProject = isOnTimeSupportProject;
        job.onTimeSupportProjectId = isOnTimeSupportProject ? onTimeSupportProjectId : null;
        job.onTimeSprintId = isOnTimeSprint ? onTimeReleaseSprintId : null;
        job.isActive = active;
        const savedJob = await saveJob(job, errorHandler);
        setSavingJob(false);
        if (savedJob) {
            resetAndHide(true);
        }
    };

    const loadCompanies = useCallback(async () : Promise<void> => {
        setLoadingCompanies(true);
        const data = await getCompanies();
        data.unshift({
            companyId: 0,
            name: 'Please Select...',
        } as Company);
        setCompanies(data);
        setCompanyId(companyId);
        setLoadingCompanies(false);
    }, []);

    const loadRates = useCallback(async () : Promise<void> => {
        setLoadingRates(true);
        const data = await getRates();
        data.unshift({
            rateId: 0,
        } as Rate);
        setRates(data);
        setLoadingRates(false);
    }, []);

    const loadOnTimeProductReleases = useCallback(async () : Promise<void> => {
        setLoadingOnTimeReleases(true);
        const data = await getOnTimeReleases();
        data.unshift({
            id: 0,
            name: 'Please Select...',
        } as OnTimeRelease);
        setOnTimeProductReleases(data);
        setLoadingOnTimeReleases(false);
    }, []);

    const loadOnTimeProjects = useCallback(async () : Promise<void> => {
        setLoadingOnTimeProjects(true);
        const data = await getOnTimeProjects();
        data.unshift({
            id: 0,
            name: 'Please Select...',
        } as OnTimeProject);
        setOnTimeProjects(data);
        setLoadingOnTimeProjects(false);
    }, []);

    const loadJob = useCallback(async () : Promise<void> => {
        if (onTimeProductReleases) {
            const job = jobId > 0 ? await getJobById(jobId, errorHandler) : null;
            setCompanyId(job?.companyId as number || 0);
            setJobName(job?.jobName as string || '');
            setRateId(job?.rateId as number || 0);
            setDescription(job?.description as string || '');
            setActive(job?.isActive as boolean || true);
            setIsOnTimeSprint(job?.isOnTimeProject as boolean || false);
            setIsOnTimeSupportProject(job?.isOnTimeSupportProject as boolean || false);
            setOnTimeSupportProjectId(0);
            setOnTimeReleaseProductId(0);
            setOnTimeReleaseVersionId(0);
            setOnTimeReleaseSprintId(0);
            if (job) {
                if (job.isOnTimeProject) {
                    let sprintFound = false;
                    for (let r = 0; r < onTimeProductReleases.length; r += 1) {
                        if (sprintFound) {
                            break;
                        }
                        const product = onTimeProductReleases[r];
                        if (product.children) {
                            for (let v = 0; v < product.children.length; v += 1) {
                                if (sprintFound) {
                                    break;
                                }
                                const version = product.children[v];
                                if (version.children) {
                                    for (let s = 0; s < version.children.length; s += 1) {
                                        const sprint = version.children[s];
                                        if (sprint.id === job.onTimeSprintId) {
                                            setOnTimeReleaseProductId(product.id as number);
                                            setOnTimeReleaseVersionId(version.id as number);
                                            setOnTimeReleaseSprintId(sprint.id as number);
                                            sprintFound = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else if (job.isOnTimeSupportProject) {
                    setOnTimeSupportProjectId(job.onTimeSupportProjectId as number);
                }
            }
        }
    }, [jobId, onTimeProductReleases]);

    useEffect(() => {
        let sprints = new Array<OnTimeRelease>();
        if (onTimeReleaseVersionId > 0) {
            const onTimeVersionRelease = onTimeVersionReleases
                .find((p) => p.id === onTimeReleaseVersionId);
            if (onTimeVersionRelease) {
                sprints = [...onTimeVersionRelease.children || []];
            }
        }
        sprints.unshift({
            id: 0,
            name: 'Please Select...',
        } as OnTimeRelease);
        setOnTimeSprintReleases(sprints);
    }, [onTimeReleaseVersionId]);

    useEffect(() => {
        let versions = new Array<OnTimeRelease>();
        if (onTimeReleaseProductId > 0 && onTimeProductReleases) {
            const onTimeProductRelease = onTimeProductReleases
                .find((p) => p.id === onTimeReleaseProductId);
            if (onTimeProductRelease) {
                versions = [...onTimeProductRelease.children || []];
            }
        }
        versions.unshift({
            id: 0,
            name: 'Please Select...',
        } as OnTimeRelease);
        setOnTimeVersionReleases(versions);
    }, [onTimeReleaseProductId]);

    useEffect(() => {
        if (isOnTimeSprint) {
            setIsOnTimeSupportProject(false);
        }
    }, [isOnTimeSprint]);

    useEffect(() => {
        if (isOnTimeSupportProject) {
            setIsOnTimeSprint(false);
        }
    }, [isOnTimeSupportProject]);

    useEffect(() => {
        loadJob();
    }, [loadJob]);

    useEffect(() => {
        loadCompanies();
        loadRates();
        loadOnTimeProductReleases();
        loadOnTimeProjects();
    }, []);

    const onCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setCompanyId(parseFloat(event.target.value));
    };

    const onRateChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setRateId(parseFloat(event.target.value));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
        event.stopPropagation();
        save();
    };

    const jobNameChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setJobName(event.target.value);
    };

    const descriptionChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setDescription(event.target.value);
    };

    const activeChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setActive(event.target.checked);
    };

    const isOnTimeSprintChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setIsOnTimeSprint(event.target.checked);
    };

    const isOnTimeSupportProjectChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setIsOnTimeSupportProject(event.target.checked);
    };

    const onTimeReleaseProductChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setOnTimeReleaseProductId(parseFloat(event.target.value));
        setOnTimeReleaseVersionId(0);
        setOnTimeReleaseSprintId(0);
    };

    const onTimeReleaseVersionChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setOnTimeReleaseVersionId(parseFloat(event.target.value));
        setOnTimeReleaseSprintId(0);
    };

    const onTimeReleaseSprintChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setOnTimeReleaseSprintId(parseFloat(event.target.value));
    };

    const onTimeSupportProjectChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setOnTimeSupportProjectId(parseFloat(event.target.value));
    };

    const resetAndHide = (refresh: boolean) : void => {
        setCompanyId(0);
        setJobName('');
        setRateId(0);
        setDescription('');
        setActive(true);
        setIsOnTimeSprint(false);
        setIsOnTimeSupportProject(false);
        setOnTimeSupportProjectId(0);
        setOnTimeReleaseProductId(0);
        setOnTimeReleaseVersionId(0);
        setOnTimeReleaseSprintId(0);
        onHide(refresh);
    };

    return (
        <>
            <Form className="mt-2 mb-2" noValidate onSubmit={handleSubmit}>
                <Modal
                  show={show}
                  onHide={() => resetAndHide(false)}
                  size="xl"
                  backdrop="static"
                  keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{jobId > 0 ? `Edit Job: ${jobId}` : 'New Job'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {savingJob ? <Loading loadingMessage="Saving job, please wait..." />
                            : (
                                <>
                                    <Tabs
                                      activeKey={activeTab as string}
                                      onSelect={(k) => setActiveTab(k)}
                                      className="mb-3"
                                    >
                                        <Tab eventKey="details" title="Details">

                                            <Form.Group as={Row} className="mb-2 gx-2">
                                                <Form.Label column lg={2}>
                                                    Company
                                                </Form.Label>
                                                <Col>
                                                    <InputGroup className="mb-2 mb-md-0">
                                                        <Form.Select
                                                          value={companyId}
                                                          onChange={onCompanyChange}
                                                          disabled={loadingCompanies}
                                                          isInvalid={companyId < 1}
                                                          isValid={companyId > 0}
                                                        >
                                                            {
                                                                companies.map((company) => (
                                                                    <option
                                                                      key={company.companyId}
                                                                      value={company.companyId}
                                                                    >
                                                                        {company.name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                        {loadingCompanies || syncingCompanies
                                                            ? <InputGroupSpinner className="rounded-ends" />
                                                            : (
                                                                <OverlayTrigger
                                                                  placement="top"
                                                                  overlay={<Tooltip>Sync Companies</Tooltip>}
                                                                >
                                                                    <Button
                                                                      variant="secondary"
                                                                      className="rounded-ends"
                                                                      onClick={() => beginSyncingCompanies()}
                                                                    >
                                                                        <ArrowRepeat />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            )}
                                                        <Form.Control.Feedback type="invalid">
                                                            Please select a company.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2 gx-2">
                                                <Form.Label column lg={2}>
                                                    Job Name
                                                </Form.Label>
                                                <Col>
                                                    <InputGroup className="mb-2 mb-md-0">
                                                        <Form.Control
                                                          value={jobName}
                                                          onChange={jobNameChange}
                                                          isInvalid={jobName == null
                                                     || jobName.trim().length < 1}
                                                          isValid={jobName?.trim().length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter a job name.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2 gx-2">
                                                <Form.Label column lg={2}>
                                                    Description
                                                </Form.Label>
                                                <Col>
                                                    <InputGroup className="mb-2 mb-md-0">
                                                        <FormControl
                                                          as="textarea"
                                                          value={description}
                                                          onChange={descriptionChange}
                                                          isInvalid={description == null || description.trim().length < 1}
                                                          isValid={description?.trim().length > 0}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please enter a description.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2 gx-2">
                                                <Form.Label column lg={2}>
                                                    Rate
                                                </Form.Label>
                                                <Col>
                                                    <InputGroup className="mb-2 mb-md-0">
                                                        <InputGroup.Text>$</InputGroup.Text>
                                                        <Form.Select
                                                          value={rateId}
                                                          onChange={onRateChange}
                                                          disabled={loadingRates}
                                                          isInvalid={rateId < 1}
                                                          isValid={rateId > 0}
                                                        >
                                                            {
                                                                rates.map((r) => (
                                                                    <option
                                                                      key={r.rateId}
                                                                      value={r.rateId}
                                                                    >
                                                                        {r.rateId as number > 0
                                                                            ? r.rateAmount?.toFixed(2)
                                                                            : 'Please Select...'}
                                                                    </option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                        {loadingRates ? <InputGroupSpinner /> : null}
                                                        <Form.Control.Feedback type="invalid">
                                                            Please select a rate.
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2 gx-2">
                                                <Col lg={{ offset: 2 }}>
                                                    <Form.Check>
                                                        <Form.Check.Input
                                                          id="isActive"
                                                          type="checkbox"
                                                          checked={active}
                                                          onChange={activeChange}
                                                        />
                                                        <Form.Check.Label
                                                          className="ms-2"
                                                          htmlFor="isActive"
                                                        >
                                                            Active
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2 gx-2">
                                                <Col lg={{ offset: 2 }}>
                                                    <Form.Check>
                                                        <Form.Check.Input
                                                          id="isOnTimeSprint"
                                                          type="checkbox"
                                                          checked={isOnTimeSprint}
                                                          onChange={isOnTimeSprintChange}
                                                        />
                                                        <Form.Check.Label
                                                          className="ms-2"
                                                          htmlFor="isOnTimeSprint"
                                                        >
                                                            Is OnTime Project Job
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group as={Row} className="mb-2 gx-2">
                                                <Col lg={{ offset: 2 }}>
                                                    <Form.Check>
                                                        <Form.Check.Input
                                                          id="isOnTimeProject"
                                                          type="checkbox"
                                                          checked={isOnTimeSupportProject}
                                                          onChange={isOnTimeSupportProjectChange}
                                                        />
                                                        <Form.Check.Label
                                                          className="ms-2"
                                                          htmlFor="isOnTimeProject"
                                                        >
                                                            Is OnTime Support Job
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Col>
                                            </Form.Group>
                                            {isOnTimeSprint
                                                ? (
                                                    <>
                                                        <Form.Group as={Row} className="mb-2 gx-2">
                                                            <Form.Label column lg={2}>
                                                                Product
                                                            </Form.Label>
                                                            <Col>
                                                                <InputGroup className="mb-2 mb-md-0">
                                                                    <Form.Select
                                                                      value={onTimeReleaseProductId}
                                                                      onChange={onTimeReleaseProductChange}
                                                                      disabled={loadingOnTimeReleases}
                                                                      isInvalid={onTimeReleaseProductId < 1}
                                                                      isValid={onTimeReleaseProductId > 0}
                                                                    >
                                                                        {
                                                                            onTimeProductReleases
                                                                                ? onTimeProductReleases
                                                                                    .map((r) => (
                                                                                        <option
                                                                                          key={r.id}
                                                                                          value={r.id}
                                                                                        >
                                                                                            {r.name}
                                                                                        </option>
                                                                                    )) : null
                                                                        }
                                                                    </Form.Select>
                                                                    {loadingOnTimeReleases
                                                                        ? <InputGroupSpinner className="rounded-ends" />
                                                                        : (
                                                                            <OverlayTrigger
                                                                              placement="top"
                                                                              overlay={<Tooltip>Refresh</Tooltip>}
                                                                            >
                                                                                <Button
                                                                                  variant="secondary"
                                                                                  className="rounded-ends"
                                                                                  onClick={() => loadOnTimeProductReleases()}
                                                                                >
                                                                                    <ArrowRepeat />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        )}
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please select a product.
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-2 gx-2">
                                                            <Form.Label column lg={2}>
                                                                Version
                                                            </Form.Label>
                                                            <Col>
                                                                <InputGroup className="mb-2 mb-md-0">
                                                                    <Form.Select
                                                                      value={onTimeReleaseVersionId}
                                                                      onChange={onTimeReleaseVersionChange}
                                                                      disabled={loadingOnTimeReleases}
                                                                      isInvalid={onTimeReleaseVersionId < 1}
                                                                      isValid={onTimeReleaseVersionId > 0}
                                                                    >
                                                                        {
                                                                            onTimeVersionReleases
                                                                                .map((r) => (
                                                                                    <option
                                                                                      key={r.id}
                                                                                      value={r.id}
                                                                                    >
                                                                                        {r.name}
                                                                                    </option>
                                                                                ))
                                                                        }
                                                                    </Form.Select>
                                                                    {loadingOnTimeReleases
                                                                        ? <InputGroupSpinner /> : null}
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please select a version.
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                        <Form.Group as={Row} className="mb-2 gx-2">
                                                            <Form.Label column lg={2}>
                                                                Sprint
                                                            </Form.Label>
                                                            <Col>
                                                                <InputGroup className="mb-2 mb-md-0">
                                                                    <Form.Select
                                                                      value={onTimeReleaseSprintId}
                                                                      onChange={onTimeReleaseSprintChange}
                                                                      disabled={loadingOnTimeReleases}
                                                                      isInvalid={onTimeReleaseSprintId < 1}
                                                                      isValid={onTimeReleaseSprintId > 0}
                                                                    >
                                                                        {
                                                                            onTimeSprintReleases
                                                                                .map((r) => (
                                                                                    <option
                                                                                      key={r.id}
                                                                                      value={r.id}
                                                                                    >
                                                                                        {r.name}
                                                                                    </option>
                                                                                ))
                                                                        }
                                                                    </Form.Select>
                                                                    {loadingOnTimeReleases
                                                                        ? <InputGroupSpinner /> : null}
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please select a sprint.
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                )
                                                : <></>}
                                            {isOnTimeSupportProject
                                                ? (
                                                    <>
                                                        <Form.Group as={Row} className="mb-2 gx-2">
                                                            <Form.Label column lg={2}>
                                                                Project
                                                            </Form.Label>
                                                            <Col>
                                                                <InputGroup className="mb-2 mb-md-0">
                                                                    <Form.Select
                                                                      value={onTimeSupportProjectId}
                                                                      onChange={onTimeSupportProjectChange}
                                                                      disabled={loadingOnTimeProjects}
                                                                      isInvalid={onTimeSupportProjectId < 1}
                                                                      isValid={onTimeSupportProjectId > 0}
                                                                    >
                                                                        {
                                                                            onTimeProjects.map((r) => (
                                                                                <option
                                                                                  key={r.id}
                                                                                  value={r.id}
                                                                                >
                                                                                    {r.name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </Form.Select>
                                                                    {loadingOnTimeProjects
                                                                        ? <InputGroupSpinner className="rounded-ends" />
                                                                        : (
                                                                            <OverlayTrigger
                                                                              placement="top"
                                                                              overlay={<Tooltip>Refresh</Tooltip>}
                                                                            >
                                                                                <Button
                                                                                  variant="secondary"
                                                                                  className="rounded-ends"
                                                                                  onClick={() => loadOnTimeProjects()}
                                                                                >
                                                                                    <ArrowRepeat />
                                                                                </Button>
                                                                            </OverlayTrigger>
                                                                        )}
                                                                    <Form.Control.Feedback type="invalid">
                                                                        Please select a project.
                                                                    </Form.Control.Feedback>
                                                                </InputGroup>
                                                            </Col>
                                                        </Form.Group>
                                                    </>
                                                )
                                                : <></>}
                                            <Form.Group as={Row} className="mb-2 gx-2 justify-content-end">
                                                <Col lg="auto">
                                                    <Button
                                                      className="w-100 mb-2 mb-lg-0"
                                                      variant="secondary"
                                                      onClick={() => resetAndHide(false)}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Col>
                                                <Col lg="auto">
                                                    <Button
                                                      className="w-100"
                                                      variant="primary"
                                                      onClick={() => save()}
                                                    >
                                                        Save Changes
                                                    </Button>
                                                </Col>
                                            </Form.Group>
                                        </Tab>
                                        <Tab eventKey="tasks" title="Tasks">
                                            <EditJobTasks jobId={jobId} errorHandler={errorHandler} />
                                        </Tab>
                                    </Tabs>
                                </>
                            )}
                    </Modal.Body>
                </Modal>
            </Form>
            <InvalidInputModal
              errors={invalidInputErrors}
              show={showInvalidInputModal}
              title={invalidInputTitle}
              onHide={() => setShowInvalidInputModal(false)}
            />
        </>
    );
};
