import { CurrentUser } from '../models/current-user';
import { ApiError, get, put } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';
import { BasicUser } from '../models/basic-user';
import { User } from '../models/user';
import { fixDates } from '../../services/date-utilities';

export const UserRepository = () : {
    getCurrentUser: () => Promise<CurrentUser | null>,
    getBasicUsers: () => Promise<BasicUser[]>,
    getUsers: (includeInactive : boolean) => Promise<User[]>,
    getUserById: (userId: number, errorHandler?: (
        title: string,
        message : string) => void) => Promise<User | null>,
    saveUser: (user: User, errorHandler?: (
        title: string,
        message : string) => void) => Promise<User | null>,
 } => {
    const { notify } = NotificationService();
    const fixData = (users: User[]) : void => {
        fixDates(users, ['created', 'modified']);
    };
    const getCurrentUser = async () : Promise<CurrentUser | null> => {
        try {
            return await get<CurrentUser>('users/current');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve user information: ${apiError.message}`, 'danger');
            return null;
        }
    };
    const getBasicUsers = async () : Promise<BasicUser[]> => {
        try {
            return await get<BasicUser[]>('users/basicusers');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve users: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const getUsers = async (includeInactive : boolean) : Promise<User[]> => {
        try {
            const users = await get<User[]>(`users?includeInactive=${includeInactive}`);
            fixData(users);
            return users;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve users: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const getUserById = async (userId: number, errorHandler?: (
        title: string,
        message : string) => void) : Promise<User | null> => {
        try {
            const user = await get<User>(`users/${userId}`);
            fixData([user]);
            return user;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to retrieve user: ${apiError.message}`;
            if (errorHandler) {
                errorHandler(apiError.title, message);
            } else {
                notify(apiError.title, message, 'danger');
            }
            return null;
        }
    };
    const saveUser = async (user: User, errorHandler?: (
        title: string,
        message : string) => void) : Promise<User | null> => {
        try {
            const savedUser = await put<User>('users', user);
            fixData([savedUser]);
            return savedUser;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to save user: ${apiError.message}`;
            if (errorHandler) {
                errorHandler(apiError.title, message);
            } else {
                notify(apiError.title, message, 'danger');
            }
            return null;
        }
    };
    return {
        getCurrentUser,
        getBasicUsers,
        getUsers,
        getUserById,
        saveUser,
    };
};
