import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { ExpanderComponentProps } from 'react-data-table-component/dist/src/DataTable/types';
import DataTable, { Media } from 'react-data-table-component';
import {
    Button,
    Col, Form, InputGroup, Row,
} from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { RestrictedContentProvider } from '../../components/restricted-content';
import { Job } from '../models/job';
import { JobRepository } from '../repositories/job-repository';
import { CompanyRepository } from '../../companies/repositories/company-repository';
import { InputGroupSpinner } from '../../components/input-group-spinner';
import { Company } from '../../companies/models/company';
import { Loading } from '../../components/loading';
import { EditJob } from './edit-job';
import { formatDateTimeString } from '../../services/date-utilities';

export const JobMaintenance = () : JSX.Element => {
    const [jobs, setJobs] = useState<Job[]>(new Array<Job>());
    const { search } = JobRepository();
    const { getCompanies } = CompanyRepository();
    const [companyId, setCompanyId] = useState<number>(-1);
    const [activeOnly, setActiveOnly] = useState<boolean>(true);
    const [companies, setCompanies] = useState<Company[]>(new Array<Company>());
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(true);
    const [loadingJobs, setLoadingJobs] = useState<boolean>(true);
    const [jobId, setJobId] = useState<number>(0);
    const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
    const searchValueInput = useRef<HTMLInputElement>(null);

    const handleCloseShowEditDialog = (refresh: boolean) : void => {
        setJobId(0);
        setShowEditDialog(false);
        if (refresh) {
            loadJobs();
        }
    };

    const loadCompanies = useCallback(async () : Promise<void> => {
        setLoadingCompanies(true);
        const data = await getCompanies();
        data.unshift({
            companyId: 0,
            name: '[All Companies]',
        } as Company);
        setCompanies(data);
        setCompanyId(0);
        setLoadingCompanies(false);
    }, []);

    const loadJobs = useCallback(async () : Promise<void> => {
        if (companyId < 0) return;
        setLoadingJobs(true);
        const data = await search({
            companyId,
            includeInActive: !activeOnly,
            searchValue: searchValueInput.current?.value,
        });
        setJobs(data);
        setLoadingJobs(false);
    }, [companyId, activeOnly]);

    useEffect(() => {
        loadJobs();
    }, [loadJobs]);

    useEffect(() => {
        loadCompanies();
    }, []);

    const columns = [
        {
            name: 'Id',
            selector: (row: Job) => row.jobId as number,
            sortable: true,
            hide: Media.MD,
            width: '80px;',
        },
        {
            name: 'Company',
            selector: (row: Job) => row.company as string,
            sortable: true,
            hide: Media.MD,
        },
        {
            name: 'Job Name',
            selector: (row: Job) => row.jobName as string,
            sortable: true,
            allowOverflow: true,
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row: Job) => (row.isActive ? 'active' : 'inactive'),
            sortable: true,
            center: true,
            hide: Media.MD,
            width: '100px;',
        },
        {
            name: 'Created',
            selector: (row: Job) => formatDateTimeString(row.created),
            sortable: true,
            right: true,
            hide: Media.LG,
            width: '140px;',
        },
        {
            name: 'Modified',
            selector: (row: Job) => formatDateTimeString(row.modified),
            sortable: true,
            right: true,
            hide: Media.LG,
            width: '140px;',
        },
        {
            cell: (row: Job) => (
                <Button
                  onClick={onEditClick}
                  id={(row.jobId as number).toString()}
                >
                    Edit
                </Button>
            ),
            button: true,
        },
    ];

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        setJobId(parseFloat(event.currentTarget.id));
        setShowEditDialog(true);
    };

    const onAddNewClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        setJobId(0);
        setShowEditDialog(true);
    };

    const onCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setCompanyId(parseFloat(event.target.value));
    };

    const onShowActiveOnlyChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setActiveOnly(event.target.checked);
    };

    const handleOnSearchSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
        event.stopPropagation();
        loadJobs();
    };

    const onClearSearchValueClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        if (searchValueInput.current) {
            searchValueInput.current.value = '';
            searchValueInput.current.focus();
            loadJobs();
        }
    };

    const expandedComponent = ({ data } : ExpanderComponentProps<Job>)
    : JSX.Element => (
        <dl className="p-2">
            <dt>Id</dt>
            <dd>{data.jobId}</dd>
            <dt>Job Name</dt>
            <dd>{data.jobName}</dd>
            <dt>Job Description</dt>
            <dd>{data.description}</dd>
            <dt>Is Active</dt>
            <dd>{data.isActive ? 'true' : 'false'}</dd>
            <dt>Company</dt>
            <dd>{data.company}</dd>
            <dt>Is OnTime Project Job</dt>
            <dd>{data.isOnTimeProject ? 'true' : 'false'}</dd>
            <dt>Is OnTime Support Job</dt>
            <dd>{data.isOnTimeSupportProject ? 'true' : 'false'}</dd>
            <dt>OnTime Description</dt>
            <dd>{data.onTimeDescription}</dd>
            <dt>Created</dt>
            <dd>{formatDateTimeString(data.created)}</dd>
            <dt>Modified</dt>
            <dd>{formatDateTimeString(data.modified)}</dd>
        </dl>
    );

    return (
        <RestrictedContentProvider projectManagerOnly>
            <>
                <EditJob
                  jobId={jobId}
                  show={showEditDialog}
                  onHide={handleCloseShowEditDialog}
                />
                <h3>Jobs</h3>
                <Form.Group as={Row} className="mb-2 gx-2 d-flex align-items-center">
                    <Form.Label column md="auto">
                        Company
                    </Form.Label>
                    <Col>
                        <InputGroup className="mb-2 mb-lg-0">
                            <Form.Select
                              value={companyId}
                              onChange={onCompanyChange}
                              disabled={loadingCompanies}
                            >
                                {
                                    companies.map((company) => (
                                        <option
                                          key={company.companyId}
                                          value={company.companyId}
                                        >
                                            {company.name}
                                        </option>
                                    ))
                                }
                            </Form.Select>
                            {loadingCompanies ? <InputGroupSpinner /> : null}
                        </InputGroup>
                    </Col>
                    <Col lg="auto">
                        <Form.Check className="mb-2 mb-lg-0">
                            <Form.Check.Input
                              id="showActiveJobsOnly"
                              type="checkbox"
                              checked={activeOnly}
                              onChange={onShowActiveOnlyChange}
                            />
                            <Form.Check.Label
                              className="ms-2"
                              htmlFor="showActiveJobsOnly"
                            >
                                Show Active Jobs Only
                            </Form.Check.Label>
                        </Form.Check>
                    </Col>
                    <Col lg="3">
                        <Form noValidate onSubmit={handleOnSearchSubmit}>
                            <InputGroup className="mb-2 mb-lg-0">
                                <Form.Control
                                  type="text"
                                  ref={searchValueInput}
                                />
                                <Button variant="secondary" type="submit" disabled={loadingJobs}><Search /></Button>
                                <Button
                                  variant="secondary"
                                  onClick={onClearSearchValueClick}
                                  disabled={loadingJobs}
                                >
                                    Clear
                                </Button>
                            </InputGroup>
                        </Form>
                    </Col>
                    <Col lg="auto">
                        <Button
                          variant="primary"
                          onClick={onAddNewClick}
                          className="w-100"
                        >
                            Add New Job
                        </Button>
                    </Col>
                </Form.Group>
                <DataTable<Job>
                  columns={columns}
                  data={jobs}
                  expandableRows
                  expandableRowsComponent={expandedComponent}
                  pagination
                  progressPending={loadingJobs}
                  progressComponent={<Loading />}
                  striped
                />
            </>
        </RestrictedContentProvider>
    );
};
