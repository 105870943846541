import React, { useCallback, useState } from 'react';
import { UserImpersonationContext } from '../services/user-impersonation-context';

export const UserImpersonationProvider = ({ children } : any) : JSX.Element => {
    const [impersonationUserId, setId] = useState<number>(0);
    const [impersonationUsername, setUsername] = useState<string>('');

    const setDetailsCallback = useCallback((id : number, username: string) : void => {
        setId(id);
        setUsername(username);
    }, []);

    const contextValue = {
        impersonationUserId,
        impersonationUsername,
        setImpersonationUserDetails: setDetailsCallback,
    };

    return (
        <UserImpersonationContext.Provider value={contextValue}>
            {children}
        </UserImpersonationContext.Provider>
    );
};
