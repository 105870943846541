import { Company } from '../models/company';
import { ApiError, get, put } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';

export const CompanyRepository = () : {
    getCompanies: () => Promise<Company[]>,
    syncCompanies: () => Promise<Company[]>
} => {
    const { notify } = NotificationService();
    const getCompanies = async () : Promise<Company[]> => {
        try {
            return await get<Company[]>('companies');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve companies: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const syncCompanies = async () : Promise<Company[]> => {
        try {
            return await put<Company[]>('companies/sync');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to sync companies: ${apiError.message}`, 'danger');
            return [];
        }
    };
    return { getCompanies, syncCompanies };
};
