import { OnTimeRelease } from '../models/ontime-release';
import { ApiError, get } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';

export const OnTimeReleaseRepository = () : {
    getOnTimeReleases: () => Promise<OnTimeRelease[]>
} => {
    const { notify } = NotificationService();
    const getOnTimeReleases = async () : Promise<OnTimeRelease[]> => {
        try {
            return await get<OnTimeRelease[]>('ontime/releases');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve on time releases: ${apiError.message}`, 'danger');
            return [];
        }
    };
    return { getOnTimeReleases };
};
