import { useContext } from 'react';
import { UserImpersonationContext } from './user-impersonation-context';

export const UserImpersonationService = () :
{
    impersonationUserId: number,
    impersonationUsername: string,
    setImpersonationUserDetails: (id: number, username: string) => void,
} => {
    const {
        impersonationUserId, impersonationUsername, setImpersonationUserDetails,
    } = useContext(UserImpersonationContext);
    return {
        impersonationUserId, impersonationUsername, setImpersonationUserDetails,
    };
};
