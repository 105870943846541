import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { Container } from 'react-bootstrap';
import { PageLayout } from './components/page-layout';
import { SignInContent } from './components/sign-in-content';
import { TimeSheet } from './components/time-sheet';
import { Dashboard } from './components/dashboard';
import { NotificationProvider } from './components/notification-provider';
import { UserImpersonationProvider } from './components/user-impersonation-provider';
import { UserMaintenance } from './users/components/user-maintenance';
import { CompanyMaintenance } from './companies/components/company-maintenance';
import { JobMaintenance } from './jobs/components/job-maintenance';
import { TaskMaintenance } from './tasks/components/task-maintenance';
import { Routes } from './services/routes';
import { CurrentUserProvider } from './components/current-user-provider';
import { OnTimeItemsSync } from './ontime/components/ontime-items-sync';
import { ScrollToTop } from './components/scroll-to-top';

function App(): JSX.Element {
    return (
        <NotificationProvider>
            <CurrentUserProvider>
                <UserImpersonationProvider>
                    <Router>
                        <ScrollToTop />
                        <PageLayout>
                            <Container className="main-container">
                                <AuthenticatedTemplate>
                                    <Route
                                      path="/"
                                      exact
                                      render={() => <Redirect to={Routes.TimeSheet} />}
                                    />
                                    <Route
                                      path={Routes.TimeSheet}
                                      exact
                                      render={() => <TimeSheet />}
                                    />
                                    <Route
                                      path={Routes.Dashboard}
                                      exact
                                      render={() => <Dashboard />}
                                    />
                                    <Route
                                      path={Routes.Users}
                                      exact
                                      render={() => <UserMaintenance />}
                                    />
                                    <Route
                                      path={Routes.Companies}
                                      exact
                                      render={() => <CompanyMaintenance />}
                                    />
                                    <Route
                                      path={Routes.Jobs}
                                      exact
                                      render={() => <JobMaintenance />}
                                    />
                                    <Route
                                      path={Routes.Tasks}
                                      exact
                                      render={() => <TaskMaintenance />}
                                    />
                                    <Route
                                      path={Routes.SyncOnTimeItems}
                                      exact
                                      render={() => <OnTimeItemsSync />}
                                    />
                                </AuthenticatedTemplate>
                                <UnauthenticatedTemplate>
                                    <SignInContent />
                                </UnauthenticatedTemplate>
                            </Container>
                        </PageLayout>
                    </Router>
                </UserImpersonationProvider>
            </CurrentUserProvider>
        </NotificationProvider>
    );
}

export default App;
