import { Rate } from '../models/rate';
import { ApiError, get } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';

export const RateRepository = () : {
    getRates: () => Promise<Rate[]>
} => {
    const { notify } = NotificationService();
    const getRates = async () : Promise<Rate[]> => {
        try {
            return await get<Rate[]>('rates');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve rates: ${apiError.message}`, 'danger');
            return [];
        }
    };
    return { getRates };
};
