import { createContext } from 'react';

export const variants = {
    info: 'info',
    success: 'success',
    danger: 'danger',
    warning: 'warning',
};

export class Notification {
    public id : number | undefined;

    public title : string | undefined;

    public message : string | undefined;

    public variant : string | undefined
}

export const NotificationContext = createContext({
    notifications: new Array<Notification>(),
    notify: <T extends keyof typeof variants>(
        title: string,
        message : string,
        variant : T) : void => { console.log('notify', title, message, variant); },
    dismiss: (id: number) : void => { console.log('dismiss', id); },
    dismissAll: () : void => { console.log('dismissAll'); },
    show: false,
    onHide: () : void => { console.log('onHide'); },
});
