import React, { useCallback, useEffect, useState } from 'react';
import {
    Button, Col, Form, InputGroup, Modal, Row,
} from 'react-bootstrap';
import { InvalidInputModal } from '../../components/invalid-input-modal';
import { UserRepository } from '../repositories/user-repository';
import { User } from '../models/user';
import { Loading } from '../../components/loading';
import { validateEmail, validateGuid } from '../../services/string-utitlities';

export const EditUser = (props: {userId : number,
    show: boolean,
    onHide: (refresh: boolean) => void}) : JSX.Element => {
    const { userId, show, onHide } = props;

    const [showInvalidInputModal, setShowInvalidInputModal] = useState<boolean>(false);
    const [invalidInputErrors, setInvalidInputErrors] = useState<string[]>(new Array<string>());
    const [invalidInputTitle, setInvalidInputTitle] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [onTimeUserId, setOnTimeUserId] = useState<number>(0);
    const [onTimeUsername, setOnTimeUsername] = useState<string>('');
    const [onTimePassword, setOnTimePassword] = useState<string>('');
    const [active, setActive] = useState<boolean>(true);
    const [savingUser, setSavingUser] = useState<boolean>(false);
    const { getUserById, saveUser } = UserRepository();
    const [applicationId, setApplicationId] = useState<string>('');

    const errorHandler = (title: string, message: string) : void => {
        setInvalidInputErrors([message]);
        setInvalidInputTitle(title);
        setShowInvalidInputModal(true);
    };

    const save = async () : Promise<void> => {
        const errors = [];
        if (username == null || username.trim().length < 1) {
            errors.push('Please enter a username.');
        }
        if (email == null || email.trim().length < 1 || !validateEmail(email)) {
            errors.push('Please enter a valid email address.');
        }
        if (applicationId != null && applicationId.trim().length > 0 && !validateGuid(applicationId)) {
            errors.push('Please enter a valid applicationId.');
        }
        if (errors.length > 0) {
            setInvalidInputErrors(errors);
            setShowInvalidInputModal(true);
            setInvalidInputTitle('Invalid Input');
            return;
        }

        setSavingUser(true);
        const user = new User();
        user.userId = userId;
        user.active = active;
        user.username = username;
        user.email = email;
        user.onTimeUserId = onTimeUserId > 0 ? onTimeUserId : null;
        user.onTimeUsername = onTimeUsername;
        user.onTimePassword = onTimePassword;
        user.applicationId = applicationId;
        const savedUser = await saveUser(user, errorHandler);
        setSavingUser(false);
        if (savedUser) {
            resetAndHide(true);
        }
    };

    const loadUser = useCallback(async () : Promise<void> => {
        console.log('loadUser', userId);
        const user = userId > 0 ? await getUserById(userId, errorHandler) : null;
        setActive(user != null ? user.active as boolean : true);
        setUsername(user?.username as string || '');
        setEmail(user?.email as string || '');
        setOnTimeUserId(user?.onTimeUserId as number || 0);
        setOnTimeUsername(user?.onTimeUsername as string || '');
        setOnTimePassword(user?.onTimePassword as string || '');
        setApplicationId(user?.applicationId as string || '');
    }, [userId]);

    useEffect(() => {
        loadUser();
    }, [loadUser]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
        event.stopPropagation();
        save();
    };

    const usernameChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setUsername(event.target.value);
    };

    const emailChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setEmail(event.target.value);
    };

    const onTimeUserIdChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setOnTimeUserId(parseFloat(event.target.value));
    };

    const onTimeUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setOnTimeUsername(event.target.value);
    };

    const onTimePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setOnTimePassword(event.target.value);
    };

    const activeChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        setActive(event.target.checked);
    };

    const applicationIdChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setApplicationId(event.target.value);
    };

    const resetAndHide = (refresh: boolean) : void => {
        setActive(true);
        setUsername('');
        setEmail('');
        setOnTimeUserId(0);
        setOnTimeUsername('');
        setOnTimePassword('');
        setApplicationId('');
        onHide(refresh);
    };

    return (
        <>
            <Form className="mt-2 mb-2" noValidate onSubmit={handleSubmit}>
                <Modal
                  show={show}
                  onHide={() => resetAndHide(false)}
                  size="xl"
                  backdrop="static"
                  keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{userId > 0 ? `Edit User: ${userId}` : 'New User'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {savingUser ? <Loading loadingMessage="Saving user, please wait..." />
                            : (
                                <>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column lg={3} xl={2}>
                                            Username
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <Form.Control
                                                  value={username}
                                                  onChange={usernameChange}
                                                  isInvalid={username == null
                                                     || username.trim().length < 1}
                                                  isValid={username?.trim().length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a username.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column lg={3} xl={2}>
                                            Email
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <Form.Control
                                                  value={email}
                                                  onChange={emailChange}
                                                  isInvalid={email == null
                                                     || email.trim().length < 1
                                                     || !validateEmail(email)}
                                                  isValid={email?.trim().length > 0 && validateEmail(email)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid email address.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column lg={3} xl={2}>
                                            Application Id
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <Form.Control
                                                  value={applicationId}
                                                  onChange={applicationIdChange}
                                                  placeholder="00000000-0000-0000-0000-000000000000"
                                                  isInvalid={applicationId != null
                                                    && applicationId.trim().length > 0
                                                    && !validateGuid(applicationId)}
                                                  isValid={applicationId != null
                                                    && applicationId?.trim().length > 0
                                                    && validateGuid(applicationId)}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a valid applicationId.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column lg={3} xl={2}>
                                            OnTime Id
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <Form.Control
                                                  type="number"
                                                  value={onTimeUserId}
                                                  onChange={onTimeUserIdChange}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column lg={3} xl={2}>
                                            OnTime Username
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <Form.Control
                                                  value={onTimeUsername}
                                                  onChange={onTimeUsernameChange}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column lg={3} xl={2}>
                                            OnTime Password
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <Form.Control
                                                  value={onTimePassword}
                                                  onChange={onTimePasswordChange}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Col lg={{ offset: 3 }} xl={{ offset: 2 }}>
                                            <Form.Check>
                                                <Form.Check.Input
                                                  id="isActive"
                                                  type="checkbox"
                                                  checked={active}
                                                  onChange={activeChange}
                                                />
                                                <Form.Check.Label
                                                  className="ms-2"
                                                  htmlFor="isActive"
                                                >
                                                    Active
                                                </Form.Check.Label>
                                            </Form.Check>
                                        </Col>
                                    </Form.Group>
                                </>
                            )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => resetAndHide(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => save()}>
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
            <InvalidInputModal
              errors={invalidInputErrors}
              show={showInvalidInputModal}
              title={invalidInputTitle}
              onHide={() => setShowInvalidInputModal(false)}
            />
        </>
    );
};
