import { ApiError, get } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';
import { OnTimeProject } from '../models/ontime-project';

export const OnTimeProjectRepository = () : {
    getOnTimeProjects: () => Promise<OnTimeProject[]>
} => {
    const { notify } = NotificationService();
    const getOnTimeProjects = async () : Promise<OnTimeProject[]> => {
        try {
            return await get<OnTimeProject[]>('ontime/projects');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve on time projects: ${apiError.message}`, 'danger');
            return [];
        }
    };
    return { getOnTimeProjects };
};
