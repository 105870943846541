import { ApiError, put } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';

export const OnTimeSyncItemsService = () : {
    syncOnTimeItems: () => Promise<unknown>
} => {
    const { notify } = NotificationService();
    const syncOnTimeItems = async () : Promise<unknown> => {
        try {
            return await put<unknown>('ontime/syncitems');
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to sync ontime items: ${apiError.message}`, 'danger');
            return [];
        }
    };
    return { syncOnTimeItems };
};
