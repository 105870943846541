import React from 'react';
import { Spinner, Stack } from 'react-bootstrap';

interface LoadingProps {
    loadingMessage?: string
}

export const Loading = ({ loadingMessage } : LoadingProps) : JSX.Element => (
    <Stack gap={3} className="mt-5 align-items-center justify-content-center">
        <Spinner animation="border" variant="primary" />
        <div>{loadingMessage}</div>
    </Stack>
);

Loading.defaultProps = {
    loadingMessage: 'Loading',
};
