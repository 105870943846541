import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import {
    Button, Col, Collapse, Form, FormControl, InputGroup, OverlayTrigger, Row, Stack, Tooltip,
} from 'react-bootstrap';
import {
    ArrowRepeat, Search, Star, StarFill, Trash,
} from 'react-bootstrap-icons';
import { TimeLog } from '../time-log/models/TimeLog';
import { Calendar } from './calendar';
import { CompanyRepository } from '../companies/repositories/company-repository';
import { Company } from '../companies/models/company';
import { Job } from '../jobs/models/job';
import { JobRepository } from '../jobs/repositories/job-repository';
import { TaskRepository } from '../tasks/repositories/task-repository';
import { Task } from '../tasks/models/task';
import { TaskDisplay } from '../tasks/components/task-display';
import { TimeLogRepository } from '../time-log/repositories/time-log-repository';
import { addDaysToDate, getDateWithoutTime } from '../services/date-utilities';
import { Loading } from './loading';
import { FavouriteRepository } from '../favourites/repositories/favourite-repository';
import { Favourite } from '../favourites/models/favourite';
import { UserImpersonationService } from '../services/user-impersonation-service';
import { InputGroupSpinner } from './input-group-spinner';
import { InvalidInputModal } from './invalid-input-modal';
import { variants } from '../services/notification-context';
import { TaskSearchResults } from '../tasks/components/task-search-results';
import { ConfirmDialog } from './confirm-dialog';
import { LoadingOverlay } from './loading-overlay';

export const TimeSheet = () : JSX.Element => {
    const [date, setDate] = useState<Date>(new Date());
    const [scrollDate, setScrollDate] = useState<Date>(new Date());
    const [timeLogs, setTimeLogs] = useState<TimeLog[]>(new Array<TimeLog>());

    const [companies, setCompanies] = useState<Company[]>(new Array<Company>());
    const [loadingCompanies, setLoadingCompanies] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<number>(0);

    const [jobs, setJobs] = useState<Job[]>(new Array<Job>());
    const [loadingJobs, setLoadingJobs] = useState<boolean>(false);
    const [jobId, setJobId] = useState<number>(0);
    const [jobDescription, setJobDescription] = useState<string>('');

    const [tasks, setTasks] = useState<Task[]>(new Array<Task>());
    const [loadingTasks, setLoadingTasks] = useState<boolean>(false);
    const [taskId, setTaskId] = useState<number>(0);

    const [task, setTask] = useState<Task | null>(null);
    const [loadingTask, setLoadingTask] = useState<boolean>(false);

    const [notes, setNotes] = useState<string>('');

    const [allowCreate, setAllowCreate] = useState<boolean>(false);
    const [allowModify, setAllowModify] = useState<boolean>(false);

    const [showDetails, setShowDetails] = useState<boolean>(true);
    const [loadingTimeLogs, setLoadingTimeLogs] = useState<boolean>(false);

    const [savingTimeLog, setSavingTimeLog] = useState<boolean>(false);
    const [deletingTimeLog, setDeletingTimeLog] = useState<boolean>(false);

    const [loadingOnTimeFeature, setLoadingOnTimeFeature] = useState<boolean>(false);

    const [favouriteId, setFavouriteId] = useState<number>(0);
    const [loadingFavourite, setLoadingFavourite] = useState<boolean>(false);
    const [favourites, setFavourites] = useState<Favourite[]>(new Array<Favourite>());

    const { getCompanies } = CompanyRepository();
    const { getJobs } = JobRepository();
    const {
        getTasks,
        getTaskById,
        getTasksByOnTimeFeature,
        syncTasks,
    } = TaskRepository();
    const {
        getTimeLogsByDateRange, saveTimeLog, getTimeLogById, deleteTimeLog,
    } = TimeLogRepository();
    const {
        getFavourites, saveFavourite, saveFavouriteAllUsers, deleteFavourite,
    } = FavouriteRepository();
    const { impersonationUserId } = UserImpersonationService();
    const [showInvalidInputModal, setShowInvalidInputModal] = useState<boolean>(false);
    const [invalidInputErrors, setInvalidInputErrors] = useState<string[]>(new Array<string>());
    const [invalidInputTitle, setInvalidInputTitle] = useState<string>('');
    const [invalidInputVariant, setInvalidInputVariant] = useState<string>(variants.danger);
    const [searchOnTimeFeatureTasksResults, setSearchOnTimeFeatureTasksResults] = useState<Task[]>(new Array<Task>());
    const [showOnTimeFeatureTasksResultsDialog, setShowOnTimeFeatureTasksResultsDialog] = useState<boolean>(false);
    const [syncingTasks, setSyncingTasks] = useState<boolean>(false);

    const [savingFavourite, setSavingFavourite] = useState<boolean>(false);
    const [savingFavouriteForAllUsers, setSavingFavouriteForAllUsers] = useState<boolean>(false);
    const [deletingFavourite, setDeletingFavourite] = useState<boolean>(false);

    const [showDeleteTimeLogDialog, setShowDeleteTimeLogDialog] = useState<boolean>(false);
    const [showDeleteFavouriteDialog, setShowDeleteFavouriteDialog] = useState<boolean>(false);
    const onTimeSearchRef = useRef<HTMLInputElement>(null);

    const onDeleteFavouriteDialogHide = (confirmed: boolean) : void => {
        setShowDeleteFavouriteDialog(false);
        if (confirmed) {
            beginDeletingFavourite();
        }
    };

    const beginDeletingFavourite = async () : Promise<void> => {
        setDeletingFavourite(true);
        const success = await deleteFavourite(favouriteId);
        if (success) {
            await loadFavourites();
        }
        setDeletingFavourite(false);
    };

    const beginSavingFavourite = async () : Promise<void> => {
        setSavingFavourite(true);
        const id = await saveFavourite(taskId);
        if (id > 0) {
            await loadFavourites();
        }
        setSavingFavourite(false);
    };

    const beginSavingFavouriteForAllUsers = async () : Promise<void> => {
        setSavingFavouriteForAllUsers(true);
        const id = await saveFavouriteAllUsers(taskId);
        if (id > 0) {
            await loadFavourites();
        }
        setSavingFavouriteForAllUsers(false);
    };

    const onHideOnTimeFeatureTasksResultsDialog = (t: Task | null) : void => {
        setShowOnTimeFeatureTasksResultsDialog(false);
        displayTask(t);
    };

    const beginSyncingTasks = async () : Promise<void> => {
        setSyncingTasks(true);
        const data = await syncTasks(jobId, false);
        if (data?.length > 0) {
            data.unshift({ taskId: 0, description: 'Please select a task...' } as Task);
        }
        setTasks(data);
        setSyncingTasks(false);
    };

    const displayTask = async (t: Task | null) : Promise<void> => {
        if (t) {
            setCompanyId(t.companyId as number);
            if (t.jobId === jobId) {
                await loadTasks();
            } else {
                setJobId(t.jobId as number);
            }
            if (t.taskId === taskId) {
                await loadTask();
            } else {
                setTaskId(t.taskId as number);
            }
        }
    };

    const onHideInvalidInputModal = () : void => setShowInvalidInputModal(false);

    const displayErrors = (<T extends keyof typeof variants>(errors: string[],
        title?: string, variant?: T) : void => {
        setInvalidInputErrors(errors);
        setShowInvalidInputModal(true);
        setInvalidInputTitle(title || 'Invalid Input');
        setInvalidInputVariant(variant || variants.danger);
    });

    const loadFavourite = useCallback(async () : Promise<void> => {
        if (favouriteId > 0) {
            setLoadingFavourite(true);
            const favourite = favouriteId > 0
                ? favourites.find((f) => f.favouriteId === favouriteId)
                : null;
            if (favourite) {
                setCompanyId(favourite.companyId as number);
                setJobId(favourite.jobId as number);
                if (favourite.taskId === taskId) {
                    await loadTask();
                } else {
                    setTaskId(favourite.taskId as number);
                }
                setNotes('');
            }
            setLoadingFavourite(false);
        }
    }, [favouriteId]);

    const handleOnFavouriteSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
        event.stopPropagation();
        if (favouriteId > 0) {
            loadFavourite();
        } else {
            displayErrors(['Please select a favourite']);
        }
    };

    const loadFavourites = useCallback(async () : Promise<void> => {
        setLoadingFavourite(true);
        const data = await getFavourites();
        data.unshift({
            favouriteId: 0,
        } as Favourite);
        setFavourites(data);
        setLoadingFavourite(false);
        setFavouriteId(data?.length > 0 ? data[0].favouriteId as number : 0);
    }, [impersonationUserId]);

    const onFavouriteChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setFavouriteId(parseFloat(event.target.value));
    };

    async function searchForOnTimeFeature() : Promise<void> {
        setLoadingOnTimeFeature(true);
        const searchValue = onTimeSearchRef.current?.value || '';
        const onTimeTasks = await getTasksByOnTimeFeature(searchValue);
        if (onTimeTasks.length > 0) {
            if (onTimeTasks.length === 1) {
                const onTimeTask = onTimeTasks[0];
                await displayTask(onTimeTask);
            } else {
                setShowOnTimeFeatureTasksResultsDialog(true);
                setSearchOnTimeFeatureTasksResults(onTimeTasks);
            }
            if (onTimeSearchRef.current) {
                onTimeSearchRef.current.value = '';
            }
        } else {
            displayErrors(['Could not find a task'], 'Not Found', 'warning');
        }
        setLoadingOnTimeFeature(false);
    }

    const handleOnTimeSearchSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
        event.stopPropagation();
        const searchValue = onTimeSearchRef.current?.value || '';
        if (searchValue.length > 0) {
            searchForOnTimeFeature();
        } else {
            displayErrors(['Please enter a search value']);
        }
    };

    async function loadCompanies(): Promise<void> {
        setLoadingCompanies(true);
        const data = await getCompanies();
        setCompanies(data);
        setLoadingCompanies(false);
        setCompanyId(data?.length > 0 ? data[0].companyId as number : 0);
    }

    async function loadTimeLog(id : number) : Promise<void> {
        const timeLog = await getTimeLogById(id);
        if (timeLog) {
            setCompanyId(timeLog.companyId as number);
            setJobId(timeLog.jobId as number);
            setNotes(timeLog.notes as string);
            if (timeLog.taskId === taskId) {
                await loadTask();
            } else {
                setTaskId(timeLog.taskId as number);
            }
        }
    }

    function clearDirty() : void {
        for (let i = 0; i < timeLogs.length; i += 1) {
            const timeLog = timeLogs[i];
            timeLog.isDirty = false;
        }
    }

    async function saveTimeLogEntry() : Promise<void> {
        setSavingTimeLog(true);
        const timeLog = timeLogs.find((tl) => tl.isDirty);
        if (timeLog) {
            timeLog.taskId = taskId;
            timeLog.notes = notes;
            const result = await saveTimeLog(timeLog);
            if (result) {
                await loadTimeLogs(false);
                await loadTask();
                setNotes('');
            }
        }
        setSavingTimeLog(false);
    }

    function startDeletingTimeLog() : void {
        const timeLog = timeLogs.find((tl) => tl.isDirty);
        if (timeLog) {
            if (timeLog.timeLogId) {
                setShowDeleteTimeLogDialog(true);
            } else {
                cancelEdit();
            }
        }
    }

    const onDeleteTimeLogDialogHide = (confirmed: boolean) : void => {
        setShowDeleteTimeLogDialog(false);
        if (confirmed) {
            deleteTimeLogEntry();
        }
    };

    async function deleteTimeLogEntry() : Promise<void> {
        setDeletingTimeLog(true);
        const timeLog = timeLogs.find((tl) => tl.isDirty);
        if (timeLog) {
            if (timeLog.timeLogId) {
                const result = await deleteTimeLog(timeLog.timeLogId as number);
                if (result) {
                    await loadTimeLogs(false);
                    await loadTask();
                    setNotes('');
                }
            } else {
                cancelEdit();
            }
        }
        setDeletingTimeLog(false);
    }

    const cancelEdit = () : void => {
        const timeLog = timeLogs.find((tl) => tl.isDirty);
        setNotes('');
        if (!timeLog) {
            return;
        }
        clearDirty();
        if (timeLog.timeLogId as number > 0) {
            loadTimeLogs(false);
        } else {
            setTimeLogs(timeLogs.filter((tl) => tl !== timeLog));
        }
    };

    const loadJobs = useCallback(async () : Promise<void> => {
        setLoadingJobs(true);
        const data = companyId ? await getJobs(companyId, false) : new Array<Job>();
        if (data?.length > 0) {
            data.unshift({ jobId: 0, jobName: 'Please select a job...' } as Job);
        }
        setJobs(data);
        setLoadingJobs(false);
    }, [companyId]);

    const loadTasks = useCallback(async () : Promise<void> => {
        setLoadingTasks(true);
        const data = jobId > 0 ? await getTasks(jobId, false) : new Array<Task>();
        if (data?.length > 0) {
            data.unshift({ taskId: 0, description: 'Please select a task...' } as Task);
        }
        setTasks(data);
        setLoadingTasks(false);
    }, [jobId]);

    const loadJobDescription = useCallback(async () : Promise<void> => {
        const job = jobId > 0 ? jobs.find((p) => p.jobId === jobId) : null;
        setJobDescription(job?.description || '');
    }, [jobId, jobs]);

    const loadTask = useCallback(async () : Promise<void> => {
        setLoadingTask(true);
        const data = taskId > 0 ? await getTaskById(taskId) : null;
        setTask(data);
        setLoadingTask(false);
    }, [taskId]);

    const loadTimeLogs = useCallback(async (resetScroll: boolean) : Promise<void> => {
        setLoadingTimeLogs(true);
        const startDate = getDateWithoutTime(date);
        const endDate = addDaysToDate(startDate, 1);
        const data = await getTimeLogsByDateRange(startDate, endDate);

        if (resetScroll) {
            let scrollHours = new Date().getHours() - 1;
            let scrollMinutes = new Date().getMinutes();
            let lastModifiedTimeLog: Date | null = null;
            for (let i = 0; i < data.length; i += 1) {
                const timeLog = data[i];
                const lastModifedTime = timeLog.modified && timeLog.modified > (timeLog.created as Date)
                    ? timeLog.modified : timeLog.created as Date;
                if (lastModifiedTimeLog == null || lastModifiedTimeLog < lastModifedTime) {
                    lastModifiedTimeLog = lastModifedTime;
                    // const ed = timeLog.endDate as Date;
                    // scrollHours = ed.getHours() - 1;
                    // scrollMinutes = ed.getMinutes();
                    const sd = timeLog.startDate as Date;
                    scrollHours = sd.getHours() - 1;
                    scrollMinutes = sd.getMinutes();
                }
            }
            if (scrollHours < 0) {
                scrollHours = 0;
            }
            const d = new Date();
            d.setHours(scrollHours);
            d.setMinutes(scrollMinutes);
            setScrollDate(d);
        }

        setTimeLogs(data);
        setLoadingTimeLogs(false);
    }, [date, impersonationUserId]);

    const getTimeLogDetails = () : TimeLog => {
        const timeLog = new TimeLog();
        const company = companyId > 0 ? companies.find((c) => c.companyId === companyId) : null;
        timeLog.company = company?.name || '?';
        const job = jobId > 0 ? jobs.find((j) => j.jobId === jobId) : null;
        timeLog.jobName = job?.jobName || '?';
        const task1 = taskId > 0 ? tasks.find((t) => t.taskId === taskId) : null;
        timeLog.taskDescription = task1?.description || '?';
        timeLog.notes = notes;
        return timeLog;
    };

    const setDirtyTimeLogValues = useCallback(() : void => {
        const timeLog = timeLogs.find((tl) => tl.isDirty);
        if (timeLog) {
            const tl = getTimeLogDetails();
            timeLog.company = tl.company;
            timeLog.jobName = tl.jobName;
            timeLog.taskDescription = tl.taskDescription;
            timeLog.notes = tl.notes;
            setTimeLogs(timeLogs.concat());
        }
    }, [companyId, companies, jobId, jobs, taskId, tasks, notes]);

    const setButtonStates = useCallback(() : void => {
        const timeLog = timeLogs.find((tl) => tl.isDirty);
        setAllowCreate((!!timeLog && !timeLog.timeLogId));
        setAllowModify((!!timeLog && !!timeLog.timeLogId));
    }, [timeLogs]);

    const onCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setJobId(0);
        setTaskId(0);
        setCompanyId(parseFloat(event.target.value));
    };

    const onJobChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setTaskId(0);
        setJobId(parseFloat(event.target.value));
    };

    const onTaskChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setTaskId(parseFloat(event.target.value));
    };

    const onNotesChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
        event.preventDefault();
        setNotes(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();
        event.stopPropagation();
        const errors = [];
        if (companyId < 1) {
            errors.push('Please select a company.');
        }
        if (jobId < 1) {
            errors.push('Please select a job.');
        }
        if (taskId < 1) {
            errors.push('Please select a task.');
        }
        if (notes == null || notes.trim().length < 1) {
            errors.push('Please enter a note.');
        }
        if (errors.length > 0) {
            displayErrors(errors);
            return;
        }
        saveTimeLogEntry();
    };

    const onDeleteClicked = () : void => {
        startDeletingTimeLog();
    };

    const checkIfEditingOtherTimeLog = (timeLog : TimeLog | null) : boolean => {
        const dirtyTimeLog = timeLogs.find((tl) => tl.isDirty);
        if (!dirtyTimeLog) {
            return true;
        }
        if (timeLog && timeLog.tempId === dirtyTimeLog.tempId) {
            return true;
        }
        // decide whether we should stop prompt user to save changes
        if (dirtyTimeLog.timeLogId as number > 0) {
            dirtyTimeLog.startDate = dirtyTimeLog.originalStartDate;
            dirtyTimeLog.endDate = dirtyTimeLog.originalEndDate;
        } else {
            timeLogs.splice(timeLogs.indexOf(dirtyTimeLog));
        }
        setTimeLogs([...timeLogs]);
        return true;
    };

    const onTimeLogTimeChanged = (id: string, startDate : Date, endDate: Date) : void => {
        const timeLog = timeLogs.find((tl) => tl.tempId === id);
        if (timeLog) {
            if (!checkIfEditingOtherTimeLog(timeLog)) {
                return;
            }
            if (!timeLog.isDirty) {
                loadTimeLog(timeLog.timeLogId as number);
            }
            clearDirty();
            timeLog.isDirty = true;
            timeLog.startDate = startDate;
            timeLog.endDate = endDate;
            setTimeLogs([...timeLogs]);
        }
    };

    const onTimeLogClicked = (id: string) : void => {
        const timeLog = timeLogs.find((tl) => tl.tempId === id);
        if (timeLog && timeLog.timeLogId as number > 0) {
            if (!checkIfEditingOtherTimeLog(timeLog)) {
                return;
            }
            clearDirty();
            timeLog.isDirty = true;
            loadTimeLog(timeLog.timeLogId as number);
            setTimeLogs([...timeLogs]);
        }
    };

    const onNewTimeSlotSelected = (startDate : Date, endDate: Date) : void => {
        if (!checkIfEditingOtherTimeLog(null)) {
            return;
        }
        clearDirty();
        const tl = getTimeLogDetails();
        setTimeLogs(timeLogs.concat({
            startDate,
            endDate,
            isDirty: true,
            tempId: TimeLog.createTempId(),
            company: tl.company,
            jobName: tl.jobName,
            taskDescription: tl.taskDescription,
            notes,
        } as TimeLog));
    };
    const onDateChanged = (newDate : Date) : void => {
        setDate(newDate);
    };

    useEffect(() => {
        setDirtyTimeLogValues();
    }, [setDirtyTimeLogValues]);

    useEffect(() => {
        setButtonStates();
    }, [setButtonStates]);

    useEffect(() => {
        loadTimeLogs(true);
    }, [loadTimeLogs]);

    useEffect(() => {
        loadTask();
    }, [loadTask]);

    useEffect(() => {
        loadTasks();
    }, [loadTasks]);

    useEffect(() => {
        loadJobDescription();
    }, [loadJobDescription]);

    useEffect(() => {
        loadJobs();
    }, [loadJobs]);

    useEffect(() => {
        loadFavourite();
    }, [loadFavourite]);

    useEffect(() => {
        loadFavourites();
    }, [loadFavourites]);

    useEffect(() => {
        loadCompanies();
    }, []);

    let loadingMessage = '';
    if (savingTimeLog) {
        loadingMessage = 'Saving, please wait...';
    }
    if (deletingTimeLog) {
        loadingMessage = 'Deleting time log, please wait...';
    }
    let crmLink = <></>;
    if (task != null && task.crmLink?.length as number > 0) {
        crmLink = <a className="small-text" href={task.crmLink} target="_blank" rel="noreferrer">{task.crmLinkText}</a>;
    }

    return (
        <LoadingOverlay show={savingTimeLog || deletingTimeLog} loadingMessage={loadingMessage}>
            <>
                <InvalidInputModal
                  errors={invalidInputErrors}
                  show={showInvalidInputModal}
                  onHide={onHideInvalidInputModal}
                  title={invalidInputTitle}
                  variant={invalidInputVariant}
                />
                <TaskSearchResults
                  tasks={searchOnTimeFeatureTasksResults}
                  show={showOnTimeFeatureTasksResultsDialog}
                  onHide={onHideOnTimeFeatureTasksResultsDialog}
                />
                <ConfirmDialog
                  message="Are you sure you want to delete this timelog entry?"
                  show={showDeleteTimeLogDialog}
                  onHide={onDeleteTimeLogDialogHide}
                  title="Delete Time Log Entry"
                  confirmButtonText="Delete"
                />
                <ConfirmDialog
                  message="Are you sure you want to delete this favourite?"
                  show={showDeleteFavouriteDialog}
                  onHide={onDeleteFavouriteDialogHide}
                  title="Delete Favourite"
                  confirmButtonText="Delete"
                />
                <Row>
                    <Col xl="7" xxl="6">
                        <Form.Group as={Row} className="gx-2">
                            <Form.Label column md={2}>
                                Favourites
                            </Form.Label>
                            <Col md="10" lg="5" xl="10">
                                <Form noValidate onSubmit={handleOnFavouriteSubmit}>
                                    <InputGroup className="mb-2 mb-lg-0 mb-xl-2">
                                        <Form.Select
                                          value={favouriteId}
                                          aria-label="Please select a favourite"
                                          onChange={onFavouriteChange}
                                          disabled={loadingFavourite}
                                        >
                                            {
                                                favourites.map((favourite) => (
                                                    <option
                                                      key={favourite.favouriteId}
                                                      value={favourite.favouriteId}
                                                    >
                                                        {favourite.favouriteId as number > 0
                                                            ? favourite.displayName
                                                            : 'Please Select...' }
                                                    </option>
                                                ))
                                            }
                                        </Form.Select>
                                        {deletingFavourite ? <InputGroupSpinner />
                                            : (
                                                <OverlayTrigger
                                                  placement="bottom"
                                                  overlay={<Tooltip>Delete Favourite</Tooltip>}
                                                >
                                                    <Button
                                                      variant="secondary"
                                                      disabled={favouriteId < 1
                                                || loadingFavourite
                                                || savingFavourite
                                                || savingFavouriteForAllUsers}
                                                      onClick={() => setShowDeleteFavouriteDialog(true)}
                                                    >
                                                        <Trash />
                                                    </Button>
                                                </OverlayTrigger>
                                            )}
                                        {loadingFavourite ? <InputGroupSpinner />
                                            : (
                                                <Button
                                                  variant="secondary"
                                                  type="submit"
                                                  disabled={favouriteId < 1
                                                || loadingFavourite
                                                || savingFavourite
                                                || savingFavouriteForAllUsers}
                                                >
                                                    Apply
                                                </Button>
                                            )}
                                    </InputGroup>
                                </Form>
                            </Col>
                            <Col md={{ offset: 2, span: 7 }} lg={{ offset: 0, span: 3 }} xl={{ offset: 2, span: 7 }}>
                                <Form noValidate onSubmit={handleOnTimeSearchSubmit}>
                                    <InputGroup className="mb-2 mb-md-0">
                                        <Form.Control
                                          ref={onTimeSearchRef}
                                        />
                                        {loadingOnTimeFeature
                                            ? <InputGroupSpinner />
                                            : (
                                                <OverlayTrigger
                                                  placement="bottom"
                                                  overlay={<Tooltip>Search by OnTime Feature or Incident</Tooltip>}
                                                >
                                                    <Button variant="secondary" type="submit"><Search /></Button>
                                                </OverlayTrigger>
                                            )}
                                    </InputGroup>
                                </Form>
                            </Col>
                            <Col md={3} lg={2} xl={3}>
                                <Button variant="secondary" className="w-100" onClick={() => setShowDetails(!showDetails)}>
                                    {showDetails ? 'Hide Details' : 'Show Details'}
                                </Button>
                            </Col>
                        </Form.Group>
                        <Form className="mt-2 mb-2" noValidate onSubmit={handleSubmit}>
                            <Collapse in={showDetails}>
                                <div>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column md={2}>
                                            Company
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <Form.Select
                                                  value={companyId}
                                                  onChange={onCompanyChange}
                                                  disabled={loadingCompanies}
                                                  isInvalid={companyId < 1}
                                                  isValid={companyId > 0}
                                                >
                                                    {
                                                        companies.map((company) => (
                                                            <option
                                                              key={company.companyId}
                                                              value={company.companyId}
                                                            >
                                                                {company.name}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {loadingCompanies ? <InputGroupSpinner /> : null}
                                                <Form.Control.Feedback type="invalid">
                                                    Please select a company.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column md={2}>
                                            Job
                                        </Form.Label>
                                        <Col>
                                            <Stack>
                                                <InputGroup className="mb-2 mb-md-0">
                                                    <Form.Select
                                                      id="jobs"
                                                      value={jobId}
                                                      onChange={onJobChange}
                                                      disabled={loadingJobs}
                                                      isInvalid={jobId < 1}
                                                      isValid={jobId > 0}
                                                    >
                                                        {
                                                            jobs.map((job) => (
                                                                <option key={job.jobId} value={job.jobId}>
                                                                    {job.jobName}
                                                                </option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    {loadingJobs ? <InputGroupSpinner /> : null}
                                                    <Form.Control.Feedback type="invalid">
                                                        Please select a job.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                                <Form.Label
                                                  column
                                                  className="small-text"
                                                  htmlFor="jobs"
                                                >
                                                    {jobDescription}
                                                </Form.Label>
                                            </Stack>
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column md={2}>
                                            Task
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2">
                                                <Form.Select
                                                  id="tasks"
                                                  value={taskId}
                                                  onChange={onTaskChange}
                                                  disabled={loadingTasks || loadingTask || syncingTasks}
                                                  isInvalid={taskId < 1}
                                                  isValid={taskId > 0}
                                                >
                                                    {
                                                        tasks.map((t) => (
                                                            <option key={t.taskId} value={t.taskId}>
                                                                {t.description}
                                                            </option>
                                                        ))
                                                    }
                                                </Form.Select>
                                                {savingFavourite ? <InputGroupSpinner />
                                                    : (
                                                        <OverlayTrigger
                                                          placement="top"
                                                          overlay={<Tooltip>Add to favourites</Tooltip>}
                                                        >
                                                            <Button
                                                              variant="secondary"
                                                              disabled={taskId < 1
                                                        || loadingTasks
                                                        || syncingTasks
                                                        || deletingFavourite
                                                        || savingFavouriteForAllUsers}
                                                              onClick={() => beginSavingFavourite()}
                                                            >
                                                                <Star />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    )}
                                                {savingFavouriteForAllUsers ? <InputGroupSpinner />
                                                    : (
                                                        <OverlayTrigger
                                                          placement="top"
                                                          overlay={<Tooltip>Add to all user favourites</Tooltip>}
                                                        >
                                                            <Button
                                                              variant="secondary"
                                                              disabled={taskId < 1
                                                        || loadingTasks
                                                        || syncingTasks
                                                        || deletingFavourite
                                                        || savingFavourite}
                                                              onClick={() => beginSavingFavouriteForAllUsers()}
                                                            >
                                                                <StarFill />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    )}
                                                {loadingTasks || syncingTasks ? <InputGroupSpinner className="rounded-ends" />
                                                    : (
                                                        <OverlayTrigger
                                                          placement="top"
                                                          overlay={<Tooltip>Sync tasks</Tooltip>}
                                                        >
                                                            <Button
                                                              variant="secondary"
                                                              disabled={jobId < 1}
                                                              className="rounded-ends"
                                                              onClick={() => beginSyncingTasks()}
                                                            >
                                                                <ArrowRepeat />
                                                            </Button>
                                                        </OverlayTrigger>
                                                    )}
                                                <Form.Control.Feedback type="invalid">
                                                    Please select a task.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                            {crmLink}
                                            <TaskDisplay
                                              task={task}
                                              loading={loadingTask}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-2 gx-2">
                                        <Form.Label column md={2}>
                                            Notes
                                        </Form.Label>
                                        <Col>
                                            <InputGroup className="mb-2 mb-md-0">
                                                <FormControl
                                                  as="textarea"
                                                  value={notes}
                                                  maxLength={1000}
                                                  onChange={onNotesChange}
                                                  isInvalid={notes == null || notes.trim().length < 1}
                                                  isValid={notes?.trim().length > 0}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please enter a note.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Form.Group>
                                </div>
                            </Collapse>
                            <Form.Group as={Row} className="gx-2">
                                <Col lg={{ span: 1, offset: 8 }} xl={{ span: 2, offset: 4 }}>
                                    <Button
                                      variant="success"
                                      type="submit"
                                      className="w-100 mb-2 mb-lg-0"
                                      disabled={!allowCreate}
                                    >
                                        Create
                                    </Button>
                                </Col>
                                <Col lg={{ span: 1 }} xl={{ span: 2 }}>
                                    <Button
                                      type="submit"
                                      className="w-100 mb-2 mb-lg-0"
                                      disabled={!allowModify}
                                    >
                                        Modify
                                    </Button>
                                </Col>
                                <Col lg={{ span: 1 }} xl={{ span: 2 }}>
                                    <Button
                                      variant="danger"
                                      className="w-100 mb-2 mb-lg-0"
                                      onClick={onDeleteClicked}
                                      disabled={!allowModify}
                                    >
                                        Delete
                                    </Button>
                                </Col>
                                <Col lg={{ span: 1 }} xl={{ span: 2 }}>
                                    <Button
                                      variant="secondary"
                                      className="w-100"
                                      onClick={cancelEdit}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </Col>
                    <Col>
                        <Calendar
                          scrollDate={scrollDate}
                          initialDate={date}
                          timeLogs={timeLogs}
                          onDateChanged={onDateChanged}
                          onNewTimeSlotSelected={onNewTimeSlotSelected}
                          onTimeLogTimeChanged={onTimeLogTimeChanged}
                          onTimeLogClicked={onTimeLogClicked}
                          loading={loadingTimeLogs}
                        />
                    </Col>
                </Row>
            </>
        </LoadingOverlay>
    );
};
