import { useContext } from 'react';
import { NotificationContext, Notification, variants } from './notification-context';

export const NotificationService = () :
{
    notifications: Notification[],
    notify: <T extends keyof typeof variants>(
    title: string,
    message : string,
    variant : T) => void,
    dismiss: (id: number) => void,
    dismissAll: () => void,
    show: boolean,
    onHide: () => void;
} => {
    const {
        notifications, notify, dismiss, dismissAll, show, onHide,
    } = useContext(NotificationContext);
    return {
        notifications, notify, dismiss, dismissAll, show, onHide,
    };
};
