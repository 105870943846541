import { PublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
import { msalConfig, loginRequest } from '../auth-config';

export const msalInstance = new PublicClientApplication(msalConfig);

export class ApiError extends Error {
    public title : string;

    constructor(title: string, msg: string) {
        super(msg);
        this.title = title;
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ApiError.prototype);
    }
}

function parseError(error: any) : ApiError {
    let title = 'Error';
    let message = 'Sorry an unhandled error has occurred!';
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        const status = error.response.status as number;
        if (status === 401 || status === 403) {
            title = 'Unauthorised';
            message = 'Unauthorised, You do not have access to this resource!';
        } else {
            title = error.response.data.title || title;
            message = error.response.data.detail || message;
        }
    }
    return new ApiError(title, message);
}

async function createClient(): Promise<AxiosInstance> {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_CLIENT_BASE_URL,
    });

    const accounts = msalInstance.getAllAccounts();
    const request = {
        ...loginRequest,
        account: accounts[0],
    };

    const promise = new Promise<string>((resolve, reject) => {
        msalInstance.acquireTokenSilent(request).then((response) => {
            resolve(response.accessToken);
        }).catch((e) => {
            // call acquireTokenPopup in case of acquireTokenSilent failure
            // due to consent or interaction required
            if (e.errorCode === 'consent_required'
            || e.errorCode === 'interaction_required'
            || e.errorCode === 'login_required') {
                msalInstance.acquireTokenPopup(request).then((response) => {
                    resolve(response.accessToken);
                }).catch((error) => {
                    reject(error);
                });
            } else {
                reject(e);
            }
        });
    });

    const accessToken = await promise;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return axiosInstance;
}

export async function get<T>(path: string): Promise<T> {
    try {
        const client = await createClient();
        const result = await client.get<T>(path);
        return result.data;
    } catch (error) {
        const apiError = parseError(error);
        throw apiError;
    }
}

export async function put<TReturn>(path: string, body?: unknown): Promise<TReturn> {
    try {
        const client = await createClient();
        const result = await client.put<TReturn>(path, body);
        return result.data;
    } catch (error) {
        const apiError = parseError(error);
        throw apiError;
    }
}

export async function post<TReturn>(path: string, body?: unknown): Promise<TReturn> {
    try {
        const client = await createClient();
        const result = await client.post<TReturn>(path, body);
        return result.data;
    } catch (error) {
        const apiError = parseError(error);
        throw apiError;
    }
}

export async function del<T>(path: string): Promise<T> {
    try {
        const client = await createClient();
        const result = await client.delete<T>(path);
        return result.data;
    } catch (error) {
        const apiError = parseError(error);
        throw apiError;
    }
}
