import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DataTable, { ExpanderComponentProps, Media } from 'react-data-table-component';
import { Loading } from '../../components/loading';
import { TaskUser } from '../../tasks/components/task-user';
import { Task } from '../../tasks/models/task';
import { TaskRepository } from '../../tasks/repositories/task-repository';
import { BasicUser } from '../../users/models/basic-user';
import { UserRepository } from '../../users/repositories/user-repository';

export const EditJobTasks = (props: { jobId: number, errorHandler: (
    title: string,
    message : string) => void }) : JSX.Element => {
    const { jobId, errorHandler } = props;
    const [loadingTasks, setLoadingTasks] = useState<boolean>(true);
    const [syncingTasks, setSyncingTasks] = useState<boolean>(false);
    const [tasks, setTasks] = useState<Task[]>(new Array<Task>());
    const { getTasks, syncTasks } = TaskRepository();
    const { getBasicUsers } = UserRepository();
    const [users, setUsers] = useState<BasicUser[]>(new Array<BasicUser>());
    const [taskId, setTaskId] = useState<number>(0);

    const startSyncingTasks = async () : Promise<void> => {
        setSyncingTasks(true);
        const data = await syncTasks(jobId, true, errorHandler);
        setTasks(data);
        setSyncingTasks(false);
    };

    const loadTasks = useCallback(async () : Promise<void> => {
        setLoadingTasks(true);
        const data = jobId > 0 ? await getTasks(jobId, true, errorHandler) : new Array<Task>();
        setTasks(data);
        setTaskId(0);
        setLoadingTasks(false);
    }, [jobId]);

    const loadUsers = useCallback(async () : Promise<void> => {
        const u = await getBasicUsers();
        setUsers(u);
    }, []);

    useEffect(() => {
        loadTasks();
    }, [loadTasks]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    if (syncingTasks) {
        return (
            <Loading loadingMessage="Syncing tasks, please wait..." />
        );
    }

    const columns = [
        {
            name: 'Id',
            selector: (row: Task) => row.taskId as number,
            sortable: true,
            width: '100px',
            hide: Media.SM,
        },
        {
            name: 'Description',
            selector: (row: Task) => row.description as string,
            sortable: true,
            allowOverflow: true,
            wrap: true,
        },
        {
            name: 'Ontime Feature #',
            selector: (row: Task) => row.onTimeFeatureId as number,
            sortable: true,
            hide: Media.LG,
            right: true,
            width: '150px',
        },
        {
            name: 'Status',
            selector: (row: Task) => row.status as string,
            sortable: true,
            hide: Media.LG,
            width: '100px',
        },
        {
            name: 'Rate',
            selector: (row: Task) => row.rateAmount || 0,
            format: (row: Task) => ((row.rateId || 0) > 0 ? `$${(row.rateAmount || 0).toFixed(2)}` : ''),
            sortable: true,
            hide: Media.LG,
            width: '100px',
        },
        {
            name: 'Deleted',
            selector: (row: Task) => (row.isDeleted ? 'true' : ''),
            sortable: true,
            center: true,
            hide: Media.LG,
            width: '100px',
        },
        {
            cell: (row: Task) => (
                <Button
                  onClick={onEditClick}
                  id={(row.taskId as number).toString()}
                >
                    Permissions
                </Button>
            ),
            button: true,
            width: '120px',
        },
    ];

    const onEditClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        setTaskId(parseFloat(event.currentTarget.id));
    };

    const onTaskEditHide = () : void => {
        setTaskId(0);
    };

    const expandedComponent = ({ data } : ExpanderComponentProps<Task>)
    : JSX.Element => (
        <dl className="p-2">
            <dt>Id</dt>
            <dd>{data.taskId}</dd>
            <dt>Name</dt>
            <dd>{data.name}</dd>
            <dt>Description</dt>
            <dd>{data.description}</dd>
            <dt>OnTime User Story Id</dt>
            <dd>{data.onTimeFeatureId}</dd>
            <dt>Status</dt>
            <dd>{data.status}</dd>
            <dt>Rate</dt>
            <dd>{((data.rateId || 0) > 0 ? `$${(data.rateAmount || 0).toFixed(2)}` : '')}</dd>
            <dt>Is Deleted</dt>
            <dd>{data.isDeleted ? 'true' : 'false'}</dd>
        </dl>
    );

    return (
        <>
            <div className={taskId > 0 ? '' : 'd-none'}>
                <TaskUser taskId={taskId} users={users} onHide={onTaskEditHide} />
            </div>
            <Row className={taskId > 0 ? 'd-none' : 'justify-content-center'}>
                <Col
                  lg="4"
                >
                    <Button
                      disabled={loadingTasks || jobId < 1}
                      variant="secondary"
                      className="w-100 mb-1"
                      onClick={() => startSyncingTasks()}
                    >
                        Sync Tasks
                    </Button>
                </Col>
            </Row>
            <div className={taskId > 0 ? 'd-none' : ''}>
                <DataTable<Task>
                  columns={columns}
                  data={tasks}
                  expandableRows
                  expandableRowsComponent={expandedComponent}
                  pagination
                  progressPending={loadingTasks}
                  progressComponent={<Loading />}
                  striped
                />
            </div>
        </>
    );
};
