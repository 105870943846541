import React, { useEffect, useState, useCallback } from 'react';
import { useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import {
    Button,
    Form,
    InputGroup,
    Nav,
    Navbar,
} from 'react-bootstrap';
import { UserImpersonationService } from '../services/user-impersonation-service';
import { BasicUser } from '../users/models/basic-user';
import { CurrentUserService } from '../services/current-user-service';
import { CurrentUser } from '../users/models/current-user';

function handleLogout(instance: IPublicClientApplication): void {
    instance.logoutRedirect().catch((e) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = (props: { users: BasicUser[] }): JSX.Element => {
    const { instance, accounts } = useMsal();
    const { username } = accounts[0];
    const { setImpersonationUserDetails, impersonationUserId } = UserImpersonationService();
    const [userId, setUserId] = useState<number>(0);
    const { currentUser } = CurrentUserService();
    const [cu, setCu] = useState<CurrentUser | null>(null);
    const { users } = props;

    const setImpersonationUser = useCallback(async () : Promise<void> => {
        if (cu && (cu.isAdmin || cu.isProjectManager)) {
            if (userId === 0 || userId === cu.userId) {
                setImpersonationUserDetails(0, '');
                return;
            }
            const user = users.find((u) => u.userId === userId);
            setImpersonationUserDetails(userId, user?.username || '');
        }
    }, [cu, userId, setImpersonationUserDetails]);

    const onUserChange = (event: React.ChangeEvent<HTMLSelectElement>) : void => {
        event.preventDefault();
        setUserId(parseFloat(event.target.value));
    };

    const onResetClick = () : void => {
        setUserId(currentUser?.userId || 0);
    };

    useEffect(() => {
        setImpersonationUser();
    }, [setImpersonationUser]);

    useEffect(() => {
        setCu(currentUser);
        setUserId(impersonationUserId > 0 ? impersonationUserId : currentUser?.userId as number);
    }, [currentUser]);

    if (users.length > 0) {
        return (
            <Nav className="d-flex">
                <Navbar.Text className="me-2">
                    {userId > 0 && cu && cu.userId !== userId ? 'Impersonating' : 'Signed in as'}
                </Navbar.Text>
                <InputGroup className="mb-2 mb-md-0">
                    <Form.Select onChange={onUserChange} value={userId} className="w-auto">
                        {
                            users.map((user) => (
                                <option
                                  key={user.userId}
                                  value={user.userId}
                                >
                                    {user.username}
                                </option>
                            ))
                        }
                    </Form.Select>
                    <Button
                      variant="secondary"
                      type="submit"
                      onClick={onResetClick}
                      disabled={userId === 0 || userId === (cu?.userId || 0)}
                    >
                        Reset
                    </Button>
                </InputGroup>
                <Nav.Link onClick={() => handleLogout(instance)}>Sign out</Nav.Link>
            </Nav>
        );
    }

    return (
        <Nav className="d-flex">
            <Navbar.Text>
                Signed in as:
                &nbsp;
                {username}
            </Navbar.Text>
            <Nav.Link className="active" onClick={() => handleLogout(instance)}>Sign out</Nav.Link>
        </Nav>
    );
};
