import React from 'react';
import { InputGroup, Spinner } from 'react-bootstrap';

export const InputGroupSpinner = (props : {className? : string}) : JSX.Element => {
    const { className } = props;
    return (
        <>
            <InputGroup.Text className={className}>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
            </InputGroup.Text>
        </>
    );
};

InputGroupSpinner.defaultProps = {
    className: '',
};
