import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable, { Media } from 'react-data-table-component';
import { Task } from '../models/task';

export const TaskSearchResults = (props: {tasks : Task[],
    show: boolean,
    onHide: (task: Task | null) => void}) : JSX.Element => {
    const { tasks, show, onHide } = props;

    const onSelectClick = (event: React.MouseEvent<HTMLButtonElement>) : void => {
        event.preventDefault();
        const taskId = parseFloat(event.currentTarget.id);
        const task = tasks.find((t) => t.taskId === taskId);
        onHide(task ?? null);
    };

    const columns = [
        {
            name: 'Id',
            selector: (row: Task) => row.taskId as number,
            sortable: true,
            width: '100px',
        },
        {
            name: 'Name',
            selector: (row: Task) => row.name as string,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Ontime Feature #',
            selector: (row: Task) => (row.isOnTimeSupportProject
                ? row.onTimeFeatureId as number + 1 : row.onTimeFeatureId as number),
            sortable: true,
            hide: Media.SM,
        },
        {
            name: 'Type',
            selector: (row: Task) => (row.isOnTimeSupportProject ? 'support' : 'feature'),
            sortable: true,
            hide: Media.SM,
        },
        {
            cell: (row: Task) => (
                <Button
                  onClick={onSelectClick}
                  id={(row.taskId as number).toString()}
                >
                    Select
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];
    const expandedComponent = ({ data } : any) : any => <pre>{JSON.stringify(data, null, 2)}</pre>;
    return (
        <Modal
          show={show}
          onHide={() => onHide(null)}
          size="xl"
          backdrop="static"
          keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Multiple Tasks Found</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataTable<Task>
                  columns={columns}
                  data={tasks}
                  expandableRows
                  expandableRowsComponent={expandedComponent}
                  pagination
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(null)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
