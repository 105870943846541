import React from 'react';
import { useMsal } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import Button from 'react-bootstrap/Button';
import { loginRequest } from '../auth-config';

function handleLogin(instance : IPublicClientApplication) : void {
    instance.loginRedirect(loginRequest).catch((e) => {
        console.error(e);
    });
}

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export const SignInButton = () : JSX.Element => {
    const { instance } = useMsal();

    return (
        <Button variant="primary" className="ml-auto" onClick={() => handleLogin(instance)}>Sign in</Button>
    );
};
