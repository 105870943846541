import { useContext } from 'react';
import { CurrentUser } from '../users/models/current-user';
import { CurrentUserContext } from './current-user-context';

export const CurrentUserService = () :
{
    currentUser: CurrentUser | null,
} => {
    const { currentUser } = useContext(CurrentUserContext);
    const cu = currentUser as unknown as CurrentUser | null;
    return {
        currentUser: cu,
    };
};
