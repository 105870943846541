import React, { useCallback, useState } from 'react';
import { NotificationContext, variants, Notification } from '../services/notification-context';

export const NotificationProvider = ({ children } : any) : JSX.Element => {
    const [notifications, setNotifications] = useState<Array<Notification>>(
        new Array<Notification>(),
    );
    const [show, setShow] = useState<boolean>(false);
    const onHide = () : void => setShow(false);

    const notifyCallback = useCallback(<T extends keyof typeof variants>(
        title: string,
        message : string,
        variant : T) : void => {
        notifications.unshift({
            title,
            message,
            variant,
        } as Notification);
        resetNotificationIds();
        setNotifications([...notifications]);
        setShow(true);
    }, [notifications]);

    const dismissCallback = useCallback((id : number) : void => {
        const notification = notifications.find((n) => n.id === id);
        if (notification) {
            notifications.splice(notifications.indexOf(notification), 1);
            resetNotificationIds();
            setNotifications([...notifications]);
        }
    }, [notifications]);

    const resetNotificationIds = () : void => {
        for (let i = 0; i < notifications.length; i += 1) {
            notifications[i].id = i;
        }
    };

    const dismissAllCallback = useCallback(() : void => {
        setNotifications([]);
    }, []);

    const contextValue = {
        notifications,
        notify: notifyCallback,
        dismiss: dismissCallback,
        dismissAll: dismissAllCallback,
        show,
        onHide,
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
};
