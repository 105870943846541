import React from 'react';
import { Modal, Spinner, Stack } from 'react-bootstrap';

interface LoadingOverlayProps {
    loadingMessage?: string,
    children: unknown
    show: boolean
}

export const LoadingOverlay = ({ children, loadingMessage, show } : LoadingOverlayProps) : JSX.Element => (
    <>
        <Modal
          show={show}
          backdrop="static"
          keyboard={false}
        >
            <Modal.Body>
                <Stack gap={3} className="align-items-center justify-content-center">
                    <Spinner animation="border" variant="primary" />
                    <div>{loadingMessage}</div>
                </Stack>
            </Modal.Body>
        </Modal>
        {children}
    </>
);

LoadingOverlay.defaultProps = {
    loadingMessage: 'Loading',
};
