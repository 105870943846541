import React from 'react';
import {
    Button, Modal,
} from 'react-bootstrap';

export const ConfirmDialog = (props: {
    title?: string,
    message: string,
    confirmButtonText?: string,
    show: boolean,
    onHide: (confirmed: boolean) => void}) : JSX.Element => {
    const {
        title,
        message,
        confirmButtonText,
        show,
        onHide,
    } = props;
    return (
        <>
            <Modal show={show} onHide={() => onHide(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => onHide(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => onHide(true)}>
                        {confirmButtonText}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

ConfirmDialog.defaultProps = {
    title: 'Invalid Input',
    confirmButtonText: 'Confirm',
};
