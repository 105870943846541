export const sumValues = <T, K extends keyof T>(array: T[], property: K): number => {
    if (array && array.length > 0) {
        return array.map((m) => m[property] as unknown as number || 0).reduce((a, b) => a + b);
    }
    return 0;
};

export const groupBy = <T, K extends keyof T>(array: T[], property: K): Map<unknown, T[]> => {
    if (array && array.length > 0) {
        const groupedMap = array.reduce(
            (entryMap, e) => entryMap.set(e[property], [...entryMap.get(e[property]) || [], e]),
            new Map(),
        );
        return groupedMap;
    }
    return new Map();
};
