import React from 'react';
import {
    Alert, Button, Modal, Stack,
} from 'react-bootstrap';
import { variants } from '../services/notification-context';

export const InvalidInputModal = (props: {
    title?: string,
    errors : string[],
    show: boolean,
    variant?: string,
    onHide: () => void}) : JSX.Element => {
    const {
        title,
        errors,
        show,
        onHide,
        variant,
    } = props;
    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack className="mt-2">
                        {
                            errors.map((e, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <Alert key={index} variant={variant} className="w-100">
                                    {e}
                                </Alert>
                            ))
                        }
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHide}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

InvalidInputModal.defaultProps = {
    title: 'Invalid Input',
    variant: variants.danger,
};
