import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Container, Nav, Navbar, Offcanvas,
} from 'react-bootstrap';
import {
    Speedometer2,
    Watch,
    Wrench,
} from 'react-bootstrap-icons';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { AppProps } from '../AppProps';
import { SignOutButton } from './sign-out-button';
import { Notifications } from './notifications';
import { UserImpersonationService } from '../services/user-impersonation-service';
import { Routes } from '../services/routes';
import { CurrentUserService } from '../services/current-user-service';
import { UserRepository } from '../users/repositories/user-repository';
import { BasicUser } from '../users/models/basic-user';

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props: AppProps) : JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const { username } = isAuthenticated ? accounts[0] : { username: '' };
    const { children } = props;
    const { impersonationUserId, impersonationUsername } = UserImpersonationService();
    const { getBasicUsers } = UserRepository();
    const { currentUser } = CurrentUserService();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isProjectManager, setIsProjectManager] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [users, setUsers] = useState<BasicUser[]>(new Array<BasicUser>());

    const loadUsers = useCallback(async () : Promise<void> => {
        let admin = false;
        let projectManager = false;
        if (currentUser) {
            admin = currentUser.isAdmin || false;
            projectManager = admin || currentUser.isProjectManager || false;
            if (projectManager) {
                const u = await getBasicUsers();
                setUsers(u);
            }
        }
        setIsAdmin(admin);
        setIsProjectManager(projectManager);
    }, [currentUser]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const navContent = (
        <Navbar.Collapse id="basic-navbar-nav">
            <SignOutButton users={users} />
            <hr />
            <Nav className="me-auto">
                <NavLink
                  to={Routes.TimeSheet}
                  className="nav-link"
                  activeClassName="active"
                  onClick={() => setExpanded(false)}
                >
                    Time Sheet
                </NavLink>
                <NavLink
                  to={Routes.Dashboard}
                  className="nav-link"
                  activeClassName="active"
                  onClick={() => setExpanded(false)}
                >
                    Dashboard
                </NavLink>
                <hr />
                { isAdmin
                    ? (
                        <NavLink
                          to={Routes.Users}
                          className="nav-link"
                          activeClassName="active"
                          onClick={() => setExpanded(false)}
                        >
                            Users
                        </NavLink>
                    )
                    : <div className="disabled-menu-item">Users</div> }
                { isProjectManager
                    ? (
                        <NavLink
                          to={Routes.Companies}
                          className="nav-link"
                          activeClassName="active"
                          onClick={() => setExpanded(false)}
                        >
                            Companies
                        </NavLink>
                    )
                    : <div className="disabled-menu-item">Companies</div> }
                { isProjectManager
                    ? (
                        <NavLink
                          to={Routes.Jobs}
                          className="nav-link"
                          activeClassName="active"
                          onClick={() => setExpanded(false)}
                        >
                            Jobs
                        </NavLink>
                    )
                    : <div className="disabled-menu-item">Jobs</div> }
                <hr />
                { isProjectManager
                    ? (
                        <NavLink
                          to={Routes.SyncOnTimeItems}
                          className="nav-link"
                          activeClassName="active"
                          onClick={() => setExpanded(false)}
                        >
                            Sync On Time Items
                        </NavLink>
                    )
                    : <div className="disabled-menu-item">Sync On Time Items</div> }
            </Nav>
        </Navbar.Collapse>
    );

    const expandedContent = (
        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="start"
        >
            <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
                <Offcanvas.Title id="offcanvasNavbarLabel">Logit 2.0</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {navContent}
            </Offcanvas.Body>
        </Navbar.Offcanvas>
    );

    const toggleButton = (
        <Navbar.Toggle
          aria-controls="offcanvasNavbar"
          onClick={() => {
              setExpanded(true);
          }}
          className="me-2"
        />
    );

    const userNameContent = (
        <Navbar.Text className="d-none d-md-block">{username}</Navbar.Text>
    );

    const navigationBarLinks = (
        <>
            <NavLink
              to={Routes.TimeSheet}
              className="nav-bar-link"
              activeClassName="active"
              title="Time Sheet"
            >
                <Watch size={24} />
            </NavLink>
            <NavLink
              to={Routes.Dashboard}
              className="nav-bar-link"
              activeClassName="active"
              title="Dashboard"
            >
                <Speedometer2 size={24} />
            </NavLink>
            { isProjectManager
                ? (
                    <NavLink
                      to={Routes.Jobs}
                      className="nav-bar-link"
                      activeClassName="active"
                      title="Jobs"
                    >
                        <Wrench size={24} />
                    </NavLink>
                )
                : null}
        </>
    );

    return (
        <>
            <div className="sticky-top">
                <Navbar expand={false} expanded={expanded} bg="primary" variant="dark">
                    <Container>
                        <div>
                            {isAuthenticated ? toggleButton : null}
                            {isAuthenticated && expanded ? expandedContent : null}
                            <Navbar.Brand href="/">Logit 2.0</Navbar.Brand>
                            {isAuthenticated ? navigationBarLinks : null}
                        </div>
                        {isAuthenticated ? userNameContent : null}
                    </Container>
                </Navbar>
                {impersonationUserId > 0 ? (
                    <div className="impersonation-header">
                        WARNING! Impersonating
                        {' '}
                        {impersonationUsername}
                    </div>
                ) : null }
            </div>
            <Notifications />
            {children}
        </>
    );
};
