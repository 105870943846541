import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import FullCalendar, {
    DateSelectArg,
    EventInput,
    EventChangeArg,
    EventClickArg,
    DurationInput,
    EventContentArg,
} from '@fullcalendar/react';
import timeGridGridPlugin from '@fullcalendar/timegrid';
import dayGridGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
    Button,
    ButtonGroup,
    Col,
    Form,
    InputGroup,
    Row,
    Stack,
} from 'react-bootstrap';
import { TimeLog } from '../time-log/models/TimeLog';
import { formatTimeString, formatToShortIsoString, parseIsoString } from '../services/date-utilities';

interface CalendarProps {
    scrollDate: Date,
    initialDate: Date,
    timeLogs : TimeLog[],
    onDateChanged : (date : Date) => void,
    onNewTimeSlotSelected: (startDate: Date, endDate: Date) => void,
    onTimeLogTimeChanged: (id: string, startDate : Date, endDate: Date) => void,
    onTimeLogClicked: (id: string) => void,
    loading: boolean,
}

export const Calendar = ({
    scrollDate,
    initialDate,
    timeLogs,
    onDateChanged,
    onNewTimeSlotSelected,
    onTimeLogTimeChanged,
    onTimeLogClicked,
    loading,
}: CalendarProps) : JSX.Element => {
    const getTimeLogDisplay = (timeLog : TimeLog) : string => `${timeLog.company} |
       ${timeLog.jobName} | ${timeLog.taskDescription}`;
    // const getTimeLogDisplay = (timeLog : TimeLog) : string => `${timeLog.company} |
    //     ${timeLog.taskDescription}`;
    const events = new Array<EventInput>();
    for (let i = 0; i < timeLogs.length; i += 1) {
        const defaultBackgroundColor = (i + 1) % 2 === 0 ? '#ddd' : '#eee';
        const timeLog = timeLogs[i];
        const ei = {
            start: timeLog.startDate,
            end: timeLog.endDate,
            id: timeLog.tempId,
            borderColor: '#000',
            textColor: '#000',
            backgroundColor: timeLog.isDirty ? '#FD9843' : defaultBackgroundColor,
            title: getTimeLogDisplay(timeLog),
            notes: timeLog.notes || '',
        } as EventInput;
        events.push(ei);
    }

    const calendarRef = useRef<FullCalendar>(null);
    const [date, setDate] = useState<string>(formatToShortIsoString(initialDate));
    const [expanded, setExpanded] = useState<boolean>(false);
    const calculateHours = () : number => {
        const sum = timeLogs.map((event) => (
            (event.endDate as Date).getTime() - (event.startDate as Date).getTime()))
            .reduce((partialSum, a) => partialSum + a, 0);
        return sum / (1000 * 60 * 60);
    };

    const totalHours = calculateHours();

    const onSelect = (arg : DateSelectArg) : void => {
        if (loading) {
            return;
        }
        onNewTimeSlotSelected(arg.start, arg.end);
    };

    const eventChange = (arg: EventChangeArg) : void => {
        if (loading) {
            return;
        }
        onTimeLogTimeChanged(arg.event.id, (arg.event.start as Date), (arg.event.end as Date));
    };

    const eventClick = (arg: EventClickArg) : void => {
        if (loading) {
            return;
        }
        onTimeLogClicked(arg.event.id);
    };

    const onDateSelectionChanged = (arg : any) : void => {
        setDate(arg.target.value);
        const currentDate = parseIsoString(arg.target.value);
        if (currentDate) {
            onDateChanged(currentDate);
        }
    };

    const onPreviousClick = () : void => {
        const currentDate = getCurrentDate();
        if (currentDate) {
            currentDate.setDate(currentDate.getDate() - 1);
            setDate(formatToShortIsoString(currentDate));
            onDateChanged(currentDate);
        }
    };

    const onNextClick = () : void => {
        const currentDate = getCurrentDate();
        if (currentDate) {
            currentDate.setDate(currentDate.getDate() + 1);
            setDate(formatToShortIsoString(currentDate));
            onDateChanged(currentDate);
        }
    };

    const onTodayClick = () : void => {
        const now = new Date();
        setDate(formatToShortIsoString(now));
        onDateChanged(now);
    };

    const onExpandClick = () : void => {
        setExpanded(!expanded);
    };

    const onAddEightHourBlockClick = () : void => {
        const currentDate = getCurrentDate();
        if (currentDate) {
            const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 9);
            const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 17);
            onNewTimeSlotSelected(startDate, endDate);
        }
    };

    const getCurrentDate = useCallback(() : Date | null => parseIsoString(date), [date]);

    useEffect(() => {
        const currentDate = getCurrentDate();
        if (currentDate) {
            calendarRef.current?.getApi().gotoDate(currentDate);
        }
    }, [getCurrentDate]);

    useEffect(() => {
        calendarRef.current?.getApi().scrollToTime({
            hours: scrollDate.getHours(),
            minutes: scrollDate.getMinutes(),
        } as DurationInput);
    }, [scrollDate]);

    const renderNote = (arg : EventContentArg) : JSX.Element => {
        let notes = (<></>);
        if (arg.event.extendedProps.notes.length > 0) {
            notes = <span className="pre-line ms-1">{arg.event.extendedProps.notes}</span>;
        }
        return notes;
    };

    const renderInnerContent = (arg: EventContentArg) : JSX.Element => (
        <div className="fc-event-main-frame">
            <div className="fc-event-title-container">
                <div className="fc-event-title fc-sticky">
                    <b>
                        <small>
                            <>[</>
                            { formatTimeString(arg.event.start) }
                            <>&nbsp;-&nbsp;</>
                            { formatTimeString(arg.event.end) }
                            <>]</>
                            <>&nbsp;</>
                            <>[</>
                            { arg.event.title || <>&nbsp;</> }
                        </small>
                        <>]</>
                    </b>
                    { renderNote(arg) }
                </div>
            </div>
        </div>
    );

    const eventContent = (arg: EventContentArg) : JSX.Element => (
        renderInnerContent(arg)
    );

    return (
        <Stack gap={2}>
            <Row>
                <Col md={{ span: 'auto' }}>
                    <ButtonGroup className="mb-2 mb-md-0 mb-xl-2 w-100">
                        <Button variant="secondary" onClick={onPreviousClick}>Previous</Button>
                        <Button variant="secondary" onClick={onTodayClick}>Today</Button>
                        <Button variant="secondary" onClick={onNextClick}>Next</Button>
                    </ButtonGroup>
                </Col>
                <Col>
                    <InputGroup className="mb-2 mb-md-0 mb-xl-2">
                        <InputGroup.Text>Date</InputGroup.Text>
                        <Form.Control type="date" value={date} onChange={onDateSelectionChanged} />
                    </InputGroup>
                </Col>
                <Col
                  md={{ span: 'auto' }}
                  xl={{ span: 12 }}
                  className="d-flex align-items-center"
                >
                    <span
                      className="w-100 text-center text-mb-end text-xl-center text-muted fw-bold"
                    >
                        {`Total Hours: ${totalHours.toFixed(2)}`}
                    </span>
                </Col>
            </Row>
            <FullCalendar
              ref={calendarRef}
              plugins={[timeGridGridPlugin, dayGridGridPlugin, interactionPlugin]}
              headerToolbar={false}
              initialView="timeGridDay"
              allDaySlot={false}
              slotDuration="00:15"
              selectable={!loading}
              select={onSelect}
              unselectAuto={false}
              selectOverlap={false}
              eventOverlap={false}
              editable={!loading}
              events={events}
              eventChange={eventChange}
              eventClick={eventClick}
              businessHours
              navLinks
              scrollTimeReset={false}
              nowIndicator
              height={expanded ? 'auto' : '1000px'}
              eventContent={eventContent}
            />
            <Row className="justify-content-md-between">
                <Col
                  md={{ span: 'auto' }}
                  className="d-flex align-items-center"
                >
                    <Button
                      variant="secondary"
                      className="w-100 mb-2 mb-md-0"
                      disabled={events.length > 0}
                      onClick={onAddEightHourBlockClick}
                    >
                        Add 8 Hour Block
                    </Button>
                </Col>
                <Col
                  md={{ span: 'auto' }}
                  className="d-flex align-items-center"
                >
                    <Button
                      variant="secondary"
                      className="w-100"
                      onClick={onExpandClick}
                    >
                        {expanded ? 'Collapse' : 'Expand'}
                    </Button>
                </Col>
            </Row>
        </Stack>
    );
};

Calendar.defaultProps = {
};
