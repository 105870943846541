import { ApiError, get, put } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';
import { UserTask } from '../models/user-task';
import { UserTaskPermissions } from '../models/user-task-permissions';

export const UserTaskRepository = () : {
    getUserTasks: (taskId: number) => Promise<UserTask[]>,
    saveUserTask: (userTask: UserTask) => Promise<UserTask | null>,
    getUserTaskPermissions: (taskId: number) => Promise<UserTaskPermissions | null>,
    saveUserTaskPermissions: (userTaskPermissions: UserTaskPermissions) => Promise<UserTaskPermissions | null>
} => {
    const { notify } = NotificationService();
    const getUserTasks = async (taskId: number) : Promise<UserTask[]> => {
        try {
            return await get<UserTask[]>(`usertasks/${taskId}`);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve user tasks: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const saveUserTask = async (userTask: UserTask) : Promise<UserTask | null> => {
        try {
            return await put<UserTask>('usertasks', userTask);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to save user task: ${apiError.message}`;
            notify(apiError.title, message, 'danger');
            return null;
        }
    };
    const getUserTaskPermissions = async (taskId: number) : Promise<UserTaskPermissions | null> => {
        try {
            return await get<UserTaskPermissions>(`usertasks/${taskId}`);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve task permissions: ${apiError.message}`, 'danger');
            return null;
        }
    };
    const saveUserTaskPermissions = async (userTaskPermissions: UserTaskPermissions) : Promise<UserTaskPermissions | null> => {
        try {
            return await put<UserTaskPermissions>('usertasks', userTaskPermissions);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to save task permissions: ${apiError.message}`;
            notify(apiError.title, message, 'danger');
            return null;
        }
    };
    return {
        getUserTasks,
        saveUserTask,
        getUserTaskPermissions,
        saveUserTaskPermissions,
    };
};
