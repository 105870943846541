import React from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { Task } from '../models/task';

interface TaskDisplayProps {
    task : Task | null,
    loading: boolean,
}

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const TaskDisplay = ({
    task,
    loading,
}: TaskDisplayProps): JSX.Element => {
    if (loading) {
        return (
            <Alert variant="secondary" className="p-2 ps-3 m-0">
                <Row xs="auto">
                    <Col>
                        Loading...
                    </Col>
                </Row>
            </Alert>
        );
    }

    let hoursRemaining = 0;
    let actualHours = 0;
    let originalHours = 0;

    if (task) {
        hoursRemaining = task.hoursRemaining || 0;
        actualHours = task.actualHours || 0;
        originalHours = task.originalHours || 0;
    }

    const percentComplete = 100 - ((hoursRemaining + actualHours) > 0
        ? (hoursRemaining / (hoursRemaining + actualHours)) * 100 : 0);

    let variant = 'dark';
    if (percentComplete < 60) {
        variant = 'success';
    } else if (percentComplete < 75) {
        variant = 'warning';
    } else if (percentComplete < 100) {
        variant = 'danger';
    }

    return (
        <Alert variant={variant} className="p-2 ps-3 m-0">
            <Row xs="auto">
                <Col>
                    Task Rem hrs:
                    {' '}
                    <strong>{hoursRemaining.toFixed(2)}</strong>
                </Col>
                <Col>
                    Task Est hrs:
                    {' '}
                    <strong>{originalHours.toFixed(2)}</strong>
                </Col>
            </Row>
        </Alert>
    );
};
