import { Job } from '../models/job';
import {
    ApiError,
    get,
    post,
    put,
} from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';
import { fixDates } from '../../services/date-utilities';
import { JobSearchParameters } from '../models/job-search-parameters';

export const JobRepository = () : {
    getJobs: (companyId: number, includeInActive: boolean) => Promise<Job[]>,
    getJobById: (jobId: number, errorHandler?: (
        title: string,
        message : string) => void) => Promise<Job | null>,
    saveJob: (job: Job, errorHandler?: (
        title: string,
        message : string) => void) => Promise<Job | null>,
    search: (parameters : JobSearchParameters) => Promise<Job[]>
} => {
    const { notify } = NotificationService();
    const fixData = (jobs: Job[]) : void => {
        fixDates(jobs, ['created', 'modified']);
    };
    const getJobs = async (companyId: number, includeInActive: boolean) : Promise<Job[]> => {
        try {
            const jobs = await get<Job[]>(`companies/${companyId}/jobs?includeInactive=${includeInActive}`);
            fixData(jobs);
            return jobs;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve jobs: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const getJobById = async (jobId: number, errorHandler?: (
        title: string,
        message : string) => void) : Promise<Job | null> => {
        try {
            const job = await get<Job>(`jobs/${jobId}`);
            fixData([job]);
            return job;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to retrieve job: ${apiError.message}`;
            if (errorHandler) {
                errorHandler(apiError.title, message);
            } else {
                notify(apiError.title, message, 'danger');
            }
            return null;
        }
    };
    const saveJob = async (job: Job, errorHandler?: (
        title: string,
        message : string) => void) : Promise<Job | null> => {
        try {
            const savedJob = await put<Job>('jobs', job);
            fixData([savedJob]);
            return savedJob;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to save job: ${apiError.message}`;
            if (errorHandler) {
                errorHandler(apiError.title, message);
            } else {
                notify(apiError.title, message, 'danger');
            }
            return null;
        }
    };
    const search = async (parameters : JobSearchParameters) : Promise<Job[]> => {
        try {
            const jobs = await post<Job[]>('jobs/search', parameters);
            fixData(jobs);
            return jobs;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve jobs: ${apiError.message}`, 'danger');
            return [];
        }
    };
    return {
        getJobs,
        getJobById,
        saveJob,
        search,
    };
};
