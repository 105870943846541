import { Task } from '../models/task';
import { ApiError, get, put } from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';

export const TaskRepository = () : {
    getTasks: (jobId: number, includeAll: boolean, errorHandler?: (
        title: string,
        message : string) => void) => Promise<Task[]>,
    getTaskById: (taskId: number) => Promise<Task | null>,
    getTasksByOnTimeFeature: (searchValue: string) => Promise<Task[]>,
    syncTasks: (jobId: number, includeAll: boolean, errorHandler?: (
        title: string,
        message : string) => void) => Promise<Task[]>
} => {
    const { notify } = NotificationService();
    const getTasks = async (jobId: number, includeAll: boolean, errorHandler?: (
        title: string,
        message : string) => void) : Promise<Task[]> => {
        try {
            return await get<Task[]>(`jobs/${jobId}/tasks?includeAll=${includeAll}`);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to retrieve tasks: ${apiError.message}`;
            if (errorHandler) {
                errorHandler(apiError.title, message);
            } else {
                notify(apiError.title, message, 'danger');
            }
            return [];
        }
    };
    const getTaskById = async (taskId: number) : Promise<Task | null> => {
        try {
            return await get<Task>(`tasks/${taskId}`);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve task: ${apiError.message}`, 'danger');
            return null;
        }
    };
    const getTasksByOnTimeFeature = async (searchValue: string) : Promise<Task[]> => {
        try {
            return await get<Task[]>(`tasks/ontimefeature/${searchValue}`);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve task: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const syncTasks = async (jobId: number, includeAll: boolean, errorHandler?: (
        title: string,
        message : string) => void) : Promise<Task[]> => {
        try {
            return await put<Task[]>(`jobs/${jobId}/synctasks?includeAll=${includeAll}`);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            const message = `Failed to sync tasks: ${apiError.message}`;
            if (errorHandler) {
                errorHandler(apiError.title, message);
            } else {
                notify(apiError.title, message, 'danger');
            }
            return [];
        }
    };
    return {
        getTasks,
        getTaskById,
        getTasksByOnTimeFeature,
        syncTasks,
    };
};
