export const msalConfig = {
    auth: {
        clientId: '4b3f7290-3080-4f16-8191-2065e0c09c12',
        // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        authority: 'https://login.microsoftonline.com/cc71bb08-5955-4c1a-8da7-5c6673172c9f',
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        // Set this to "true" if you are having issues on IE11 or Edge
        storeAuthStateInCookie: false,
    },
};

// Add scopes here
export const loginRequest = {
    scopes: ['api://4b3f7290-3080-4f16-8191-2065e0c09c12/access_as_user'],
};
