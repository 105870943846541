export class Job {
    public jobId : number | undefined;

    public companyId : number | undefined;

    public jobName : string | undefined;

    public description : string | undefined;

    public rateId : number | undefined;

    public isActive : boolean | undefined;

    public isOnTimeProject : boolean | undefined;

    public onTimeSprintId : number | null | undefined;

    public isOnTimeSupportProject : boolean | undefined;

    public onTimeSupportProjectId : number | null | undefined;

    public created : Date | undefined;

    public modified : Date | null | undefined;

    public company : string | undefined;

    public onTimeDescription : string | undefined;
}
