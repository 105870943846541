import { Favourite } from '../models/favourite';
import {
    ApiError, del, get, put,
} from '../../services/api-client';
import { NotificationService } from '../../services/notification-service';
import { UserImpersonationService } from '../../services/user-impersonation-service';

export const FavouriteRepository = () : {
    getFavourites: () => Promise<Favourite[]>,
    saveFavourite: (taskId : number) => Promise<number>,
    saveFavouriteAllUsers: (taskId : number) => Promise<number>,
    deleteFavourite: (favouriteId : number) => Promise<boolean>,
} => {
    const { notify } = NotificationService();
    const { impersonationUserId } = UserImpersonationService();

    const getFavourites = async () : Promise<Favourite[]> => {
        try {
            const path = impersonationUserId > 0 ? `favourites/${impersonationUserId}` : 'favourites';
            return await get<Favourite[]>(path);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to retrieve favourites: ${apiError.message}`, 'danger');
            return [];
        }
    };
    const saveFavourite = async (taskId : number) : Promise<number> => {
        try {
            const path = impersonationUserId > 0
                ? `favourites/${taskId}/${impersonationUserId}` : `favourites/${taskId}`;
            return await put<number>(path);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to save favourite: ${apiError.message}`, 'danger');
            return 0;
        }
    };
    const saveFavouriteAllUsers = async (taskId : number) : Promise<number> => {
        try {
            const path = `favourites/allusers/${taskId}`;
            return await put<number>(path);
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to save favourite: ${apiError.message}`, 'danger');
            return 0;
        }
    };
    const deleteFavourite = async (favouriteId : number) : Promise<boolean> => {
        try {
            const path = `favourites/${favouriteId}`;
            await del(path);
            return true;
        } catch (error : unknown) {
            const apiError = error as ApiError;
            notify(apiError.title, `Failed to delete favourite: ${apiError.message}`, 'danger');
            return false;
        }
    };
    return {
        getFavourites, saveFavourite, saveFavouriteAllUsers, deleteFavourite,
    };
};
